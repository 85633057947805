.checklist-menus {
	padding: 20px; // 10px
	.dash-title {
		display: none;
		color: #af281d;
		font-weight: 800;
		font-size: 1.5rem; //32px
		border-bottom: 1px solid #e0e0e0;
		margin-bottom: 10px;
	}
	.checklist-menu {
		padding-top: 1rem;
		display: grid;
		grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
		gap: 10px;
		color: #ffffff;
		a,
		.anc {
			color: #ffffff;
			cursor: pointer;
		}
		.checklist-item {
			background-color: #aaaaaa;
			border-radius: 0.625rem;
			display: flex;
			height: 10rem;
			overflow: hidden;
			cursor: pointer;
			flex: 1 1 100%;
			// min-width: max-content;
			.icon-slot {
				width: 4.68rem;
				display: flex;
				justify-content: center;
				align-items: center;
				img {
					width: 3rem;
					height: 3rem;
					object-fit: contain;
				}
			}
			.text-slot {
				flex: auto;
				display: flex;
				flex-direction: column;
				justify-content: center;
				line-height: 1.3;
				justify-content: center;
				align-items: center;
				.title {
					font-weight: 700;
				}
				.description {
					font-size: 0.75rem;
					font-style: italic;
					margin-top: unset;
				}
			}
			.arrow-slot {
				width: 2rem;
				display: flex;
				align-items: center;
				.icon {
					width: 1.25rem;
					height: 1.25rem;
					stroke-width: 1;
				}
			}
		}
	}
	.checklist-pagination {
		margin-top: 1rem;
	}
	&.root {
		.checklist-menu {
			grid-template-columns: 1fr 1fr;
			.checklist-item {
				padding: 0.5rem;
				text-align: center;
				min-height: 100px;
				.icon-slot,
				.arrow-slot {
					display: none;
				}
				.text-slot {
					font-size: 1.3rem;
					display: flex;
					justify-content: center;
					align-items: center;
					.description {
						font-style: normal;
					}
				}
			}
		}
	}
}

.critical {
	color: var(--headings-color);
	font-weight: bold;
}

@media screen and (min-width: 512px) {
	.checklist-menus {
		.dash-title {
			display: block;
		}
		&.root {
			.dash-title {
				display: none;
			}
		}
	}
}

@media screen and (min-width: 1100px) {
	.checklist-menus {
		.dash-title {
			color: var(--headings-color);
			font-weight: 800;
			font-size: 2rem; //32px
			border-bottom: 1px solid #e0e0e0;
		}
		.checklist-menu {
			padding-top: 1rem;
			display: grid;
			grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
			gap: 0.625rem; // 10px
			color: #ffffff;
			.checklist-item {
				background-color: #aaaaaa;
				border-radius: 0.625rem;
				display: flex;
				height: 8rem;
				overflow: hidden;
				cursor: pointer;
				flex: 0 1 30%;
				min-width: max-content;
				.icon-slot {
					width: 5rem;
					display: flex;
					justify-content: center;
					align-items: center;
					img {
						width: 3rem;
						height: 3rem;
						object-fit: contain;
					}
				}
				.text-slot {
					flex: auto;
					display: flex;
					flex-direction: column;
					justify-content: center;
					.title {
						font-weight: 700;
						font-size: 1.2rem;
					}
					.description {
						font-size: 0.75rem;
						font-style: italic;
						margin-top: unset;
					}
				}
				.arrow-slot {
					width: 2rem;
					display: flex;
					align-items: center;
					.icon {
						width: 1.25rem;
						height: 1.25rem;
						stroke-width: 1;
					}
				}
			}
		}
		&.root {
			.checklist-menu {
				grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
				.checklist-item {
					.icon-slot,
					.arrow-slot {
						display: none;
					}
					.text-slot {
						font-size: 1.5rem;
						display: flex;
						justify-content: center;
						align-items: center;
						.description {
							font-style: normal;
						}
					}
				}
			}
		}
	}
}

.checklist-menu-header {
	display: flex;
	justify-content: space-between;
	align-items: flex-end;
}

.change-go-live-date-modal {
	.button-area {
		display: flex;
		justify-content: flex-end;
		gap: 1rem;
		margin-bottom: 1rem;
		.button {
			padding: 0.5rem;
			display: flex;
			align-items: center;
			.loader {
				width: 1rem;
				height: 1rem;
			}
		}
	}
	.go-live-date {
		color: gray;
		font-weight: bold;
		padding: 0.25rem;
	}
}

.checklist-card {
	background-color: #aaaaaa;
	border-radius: 0.625rem;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	height: 110px;
	overflow: hidden;
	cursor: pointer;
	flex: 0 1 30%;
	.top {
		padding-top: 0.25rem;
		padding-left: 0.5rem;
	}
	.icon-slot {
		width: 4.68rem;
		display: flex;
		justify-content: center;
		align-items: center;
		img {
			width: 3rem;
			height: 3rem;
			object-fit: contain;
		}
	}
	.text-slot {
		flex: auto;
		display: flex;
		flex-direction: column;
		justify-content: center;
		line-height: 1.3;
		justify-content: center;
		align-items: center;
		padding-left: 0.5rem;
		padding-right: 0.5rem;
		.title {
			font-weight: 700;
			text-align: center;
			width: 100%;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
			@supports (-webkit-line-clamp: 2) {
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: initial;
				display: -webkit-box;
				-webkit-line-clamp: 2;
				-webkit-box-orient: vertical;
			}
		}
		.description {
			font-size: 0.75rem;
			font-style: italic;
			margin-top: unset;
		}
	}
	.arrow-slot {
		width: 2rem;
		display: flex;
		align-items: center;
		.icon {
			width: 1.25rem;
			height: 1.25rem;
			stroke-width: 1;
		}
	}
	.bottom {
		display: flex;
		justify-content: space-between;
		.store {
			padding-left: 0.5rem;
			padding-bottom: 0.25rem;
			display: flex;
			gap: 0.5rem;
			align-items: center;
			.store-icon {
				width: 1.5rem;
				height: 1.5rem;
			}
		}
		.shared-type {
			padding-right: 0.5rem;
			padding-bottom: 0.25rem;
			.group-icon,
			.person-icon {
				width: 1.5rem;
				height: 1.5rem;
			}
		}
	}
}

@media screen and (min-width: 512px) {
}

@media screen and (min-width: 1100px) {
	.checklist-card {
		background-color: #aaaaaa;
		border-radius: 0.625rem;
		display: flex;
		height: 8rem;
		overflow: hidden;
		cursor: pointer;
		flex: 0 1 30%;
		.icon-slot {
			width: 5rem;
			display: flex;
			justify-content: center;
			align-items: center;
			img {
				width: 3rem;
				height: 3rem;
				object-fit: contain;
			}
		}
		.text-slot {
			flex: auto;
			display: flex;
			flex-direction: column;
			justify-content: center;
			.title {
				font-weight: 700;
				font-size: larger;
			}
			.description {
				font-size: 0.75rem;
				font-style: italic;
				margin-top: unset;
			}
		}
		.arrow-slot {
			width: 2rem;
			display: flex;
			align-items: center;
			.icon {
				width: 1.25rem;
				height: 1.25rem;
				stroke-width: 1;
			}
		}
	}
}

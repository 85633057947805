/* FONT DECLARATIONS */

@import url("https://fonts.googleapis.com/css?family=Open+Sans:400,400i,700,700i,800,800i");

/* FONT STACK */

$base-font: "Open Sans", Helvetica, Arial, sans-serif;
$font-size-base: 14px; //equal to 1rem
$font-weight-base: 400;
$font-weight-medium: 500;
$font-weight-bold: 600;
$font-weight-extrabold: 800;

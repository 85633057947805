.breadcrumbs {
	float: left;
	ul {
		padding-left: 15px;
		list-style: none;
		margin-top: 15px;
		a,
		.anc {
			color: $white;
			font-weight: $font-weight-base;
			text-decoration: underline;
		}
		li {
			color: $white;
			font-weight: $font-weight-base;
			display: inline;
			padding: 5px 0px;
			font-size: 1.3rem;
			@media (max-width: $tablet) {
				font-size: 1rem;
			}
			@media (max-width: $phone) {
				font-size: 0.8rem;
			}
		}
	}
}

.top-breadcrumbs {
	display: flex;
	flex-wrap: wrap;
	min-height: 50px;
	width: 100%;
	margin-top: 50px;
	align-items: center;
	.page-header {
		font-size: 1rem;
		display: flex;
		align-items: center;
		width: 100%;
		height: 50px;
		padding: 0 1rem;
		margin: 0;
	}
	.breadcrumbs-items {
		display: flex;
		flex-wrap: wrap;
		padding: 0.5rem 1rem;
		gap: 0.5rem;
		.clickable {
			display: flex;
			align-items: center;
			gap: 0.5rem;
			color: white;
			.text {
				cursor: pointer;
				text-decoration: underline;
			}
		}
		.not-clickable {
			display: flex;
			align-items: center;
			gap: 0.5rem;
			color: white;
		}
	}
}

#superSelect {
	padding: 3px 8px;
	background: lighten($yellow, 40%);
	margin-bottom: 15px;
	.minimize {
		color: $black;
		width: 100%;
		font-size: 0.9rem;
		//line-height: .8*1.5rem;
		padding: 0;
		margin-bottom: 0;
		&:hover {
			cursor: pointer;
		}
	}
	.role-switch {
		display: flex;
		align-items: center;
	}
	.role-switch input {
		width: auto;
		margin-right: 10px;
	}
	.role-switch label {
		width: auto;
	}
	p {
		font-size: 1rem;
		line-height: 1.2rem;
	}
	ul {
		list-style: none;
		width: 100%;
		padding-left: 0;
		li {
			display: inline-block;
			margin-right: 10px;
		}
	}
	form {
		width: 100%;
		label {
			font-size: 0.8em;
		}
		input,
		select {
			padding: 0;
			border: 1px solid #ffce00;
			border-radius: 2px;
			//padding: 15px 15px 15px 15px;
			width: 100%;
			background: $white;
			font-weight: 400;
			color: #333;
			font-size: 0.8rem;
			overflow: hidden;
		}
		input.active,
		select.active {
			background: $yellow;
		}
		button {
			background: $yellow;
			border: none;
		}
	}
}

.form-content {
	display: flex;
	width: 100%;
}
#form-sections {
	//width: 350px;
	display: flex;
	flex-direction: column;
	position: relative;
	border-right: 1px solid gainsboro;
	height: 100%;
}
.form-item {
	flex: 1;
	padding: 0px;
	position: relative;
	padding-left: 15px;
}
.form-item-content {
	width: 100%;
}

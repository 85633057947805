/* COLORS */
$white: #fff;
// $red: rgb(191, 12, 12);
$red: #063cde;
$yellow: #ffce00;
$orange: #ff931e;
$green: rgb(23, 172, 23);
$blue: #462e8c;
$black: #333;
$dark-grey: #8b8b8b;
$mid-grey: #ccc;
$light-grey: #f6f6f6;
$success: #32cd32;
$failure: #ff4500;

/*LAYGO COLORS */
$color-base: #666;
$color-featured: $color-base;
$color-whats-new: #f16c75;
// $color-firsts: #bf0c0c;
$color-firsts: #063cde;
$color-openclose: #475fa2;
$color-cash: #3aba97;
$color-inventory: #f79831;
$color-timekeeping: #876ca2;
$color-esmartclock: #52b1e3;
$color-settings: #87134f;
$color-profitloss: #1d3461;
$color-other: #404040;
// $color-zip: #4ba5ff;
// $color-ned: #ffc525;
$color-ourlounge: #893579;
$color-pta: #00b050;
// $color-slr: #ff931e;
$color-scheduling: #5cc3ff;
// $color-myidm-overview: #475fa2;
// $color-myidm-datapass: #3aba97;
// $color-myidm-navigating: #f79831;
// $color-myidm-id: #876ca2;
// $color-myidm-access: #52b1e3;
// $color-myidm-patches: #87134f;
// $color-myidm-crew: #3a4265;
// $color-myidm-reporting: #1d3461;
// $color-myidm-other: #404040;
// $color-fsl-teal: rgb(0, 164, 155);
// $color-fsl-red: rgb(197, 32, 51);
// $color-fsl-yellow: rgb(255, 196, 39);
// $color-fsl-purple: rgb(115, 42, 131);
// $color-fsl-blue: rgb(0, 110, 160);
// $color-fsl-gray: rgb(169, 160, 137);
// $color-fsl-green: rgb(96, 168, 69);
// $color-fsl-orange: rgb(242, 103, 42);

/* LINK COLORS */

$link: $red;
$link-focus: $red;
$link-hover: darken($red, 5%);

// globally accessible

// red; #bf0c0c, rgb(191, 12, 12);
:root {
	--headings-color: rgb(1, 43, 92);
	--links-color: #063cde;
	--button-links-bg: #063cde;
	--mcd-yellow: #ffce00;
	--mcd-orange: #ff931e;
	--mcd-blue: #063cde;
}

/*used in js*/
:export {
	black: $black;
	lightGrey: $light-grey;
}

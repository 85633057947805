.checklist-detail {
	&.main {
		padding: 0;
	}
	.checklist-header {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		.checklist-header-left {
			padding: 10px 15px;
		}
		.checklist-header-right {
			padding: 10px 15px;
			border-top: 1px solid #b1b1b1;
			border-bottom: 1px solid #b1b1b1;
		}
	}
	.check-list-table-container {
		.check-list-table {
			.checklist-table-row {
				display: flex;
				.checklist-table-header {
					width: 50%;
					margin: 0;
				}
			}
			.checklist-row {
				display: flex;
				align-items: stretch;
				td {
					width: 50%;
					display: flex;
					align-items: center;
				}
			}
			.checklist-table-row,
			.checklist-row {
				.col-task {
					width: 100%;
					.task-icon {
						width: 14px;
						height: 14px;
						flex-shrink: 0;
					}
					.is-critical-icon {
						display: flex;
						justify-content: center;
						align-items: center;
						width: 24px;
						height: 24px;
						flex-shrink: 0;
						border-radius: 100px;
						border: 3px solid var(--mcd-orange, #ff931e);
						color: var(--mcd-orange, #ff931e);
					}
					.is-event-icon {
						display: flex;
						justify-content: center;
						align-items: center;
						width: 24px;
						height: 24px;
						flex-shrink: 0;
						border-radius: 100px;
						border: 3px solid var(--mcd-blue, #063cde);
						color: var(--mcd-blue, #063cde);
					}
				}
				.col-due-date {
					width: 250px;
					flex: 0 0 auto;
				}
				.col-completed-date {
					width: 150px;
					flex: 0 0 auto;
				}
				.col-completed-by {
					width: 250px;
					flex: 0 0 auto;
				}
			}
		}
	}
}

.check-list-table {
	grid-column: 1 / span 2;
}

.check-list-table {
	width: 100%;
	border-collapse: collapse;
}

.check-list-table th,
.check-list-table td {
	// border: 1px solid #ddd;
	text-align: left;
	padding: 8px;
}
.check-list-table th {
	border-bottom: 1px solid #000;
	margin-bottom: 10px;
}
.check-list-table table {
	width: 100%;
	table-layout: fixed;
}

.check-list-table tr:nth-child(odd) td {
	background-color: #f2f2f2;
}

.checklist-due-date-container {
	display: flex;
}

.checklist-due-date-container-items {
	margin-right: 0px;
}

.checklist-circle-container {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-right: 10px;
}
.checklist-circle {
	width: 12px;
	height: 12px;
	border-radius: 50%;
}

.checklist-header-title {
	color: var(--headings-color); //styleName: Heading 1;
	font-size: 20px;
	font-weight: 700;
	text-align: left;
}

.checklist-header-right {
	color: var(--headings-color); //styleName: Heading 1;
	font-size: 20px;
	font-weight: 700;
	text-align: left;
}

.checklist-header-information {
	//styleName: Paragraph Bold;
	font-family: Open Sans;
	font-size: 14px;
	font-weight: 700;
	line-height: 22px;
	letter-spacing: 0em;
	text-align: left;
}
.listitemtask-view-container {
	button {
		cursor: pointer;
		border: none;
		color: var(--links-color);
		&:hover {
			filter: brightness(90%);
		}

		&.current,
		&.completed,
		&.all {
			background-color: var(--links-color) !important;
			color: rgb(255, 255, 255) !important;
		}
	}
}

@media screen and (max-width: 768px) {
	// mobile only
	.checklist-detail {
		.check-list-table-container {
			.check-list-table {
				.checklist-table-row,
				.checklist-row {
					.col-due-date {
						width: 30%;
						flex: 0 0 auto;
					}
				}
			}
		}
	}
	.check-list-table td {
		width: 100%;
		display: block;
	}
}

@media screen and (min-width: 510px) {
	.checklist-detail {
		&.main {
			padding: 30px 15px 60px;
		}
		.checklist-header {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			padding: 10px 15px;
			.checklist-header-left {
				padding: 0;
			}
			.checklist-header-right {
				padding: 0;
				border: none;
			}
		}
	}
}

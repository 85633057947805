.onboarding {
	.backdrop {
		position: fixed;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background: rgba($black, 0.98);
		z-index: 1050;
	}
	.content {
		position: absolute;
		top: 0;
		right: 0;
		//bottom: 0;
		left: 0;
		z-index: 999999;
	}
	.onboarding-card {
		background: $white;
		border: none;
		margin: 0 auto;
		padding: 15px;
		.note {
			color: $red;
			font-style: italic;
			font-size: 0.85rem;
		}

		@media (min-width: 650px) {
			width: 600px;
			height: auto;
		}
		h1 {
			padding-bottom: 15px;
		}
		h5 {
			font-weight: bold;
		}
		.app-icon {
			max-width: 80px;
			border: 1px solid $mid-grey;
			border-radius: 15px;
			height: auto;
			padding: 0;
			margin-bottom: 35px;
			margin-right: 20px;
			float: left;
		}
		.margin-icons {
			img {
				margin-top: 10px;
			}
			margin-bottom: 10px;
		}
		.last {
			padding-bottom: 20px;
		}
		.card-img-lg {
			width: 70%;
			text-align: center;
		}
		.card-img-sm {
			width: 50%;
		}
		input[type="checkbox"],
		input[type="radio"] {
			display: inline;
		}
		input[type="checkbox"],
		input[type="radio"] {
			width: auto;
		}
		input[type="submit"] {
			border: none;
			color: $red;
			font-size: 1rem;
			padding: 0;
			margin: 0;
			width: auto;
			height: auto;
		}
		label {
			padding-left: 5px;
		}
		span {
			display: block;
			font-size: 0.8rem;
			padding-left: 23px;
		}
	}
}

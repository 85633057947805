.custom-form-section {
	display: flex;
	align-items: center;
	cursor: pointer;
	padding-left: 10px;
	min-height: 50px;
}

.custom-form-section.custom-selected {
	border-left: 4px solid #ffcc29;
	font-weight: bold !important;
	padding-right: 5px;
	padding-left: 5px;
}

.custom-green-check-mark {
	color: green;
	padding-right: 5px;
	padding-left: 5px;
}

.custom-section-name {
	font-weight: normal;

	&.custom-completed {
		color: green;
	}
	&.custom-selected {
		font-weight: bold;
	}

	a {
		text-decoration: none;
		color: var(--links-color);

		&:hover {
			text-decoration: underline;
			font-weight: bold;
		}
	}
}
.custom-form-section.custom-selected .custom-section-name {
	font-weight: bold;
}

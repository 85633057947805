@import "_variables.scss";
/* PAGE CONTENT */

.pagesection-notopmargin {
	margin-top: 0;
}

.pagesection-moretopmargin {
	margin-top: 40px;
	margin-bottom: 20px;
}

.pagesection-margin {
	margin-bottom: 25px;
}

.section-bottommargin {
	margin-bottom: 35px;
	line-height: 1.5rem;
}

.banner-home {
	// position: fixed;
	top: 100px;
	left: 0;
	right: 0;
	margin: 0 auto;
	z-index: 99;
	background: $color-base;
	[class*="col-"] {
		padding: 15px;
	}
}

.banner {
	padding: 10px 15px;
	position: fixed;
	top: 50px;
	left: 0;
	width: 100%;
	margin: 0 auto;
	z-index: 1000;
}

.banner-featured-restaurant-firsts {
	@extend .banner;
	background: $color-firsts;
}

.banner-featured-payroll {
	@extend .banner;
	background: $color-timekeeping;
}

.banner-featured-month-end {
	@extend .banner;
	background: $color-inventory;
}

.banner-featured-commissary {
	@extend .banner;
	background: $color-inventory;
}

.banner-featured-open-close {
	@extend .banner;
	background: $color-openclose;
}
.banner-featured-pay-period {
	@extend .banner;
	background: $color-timekeeping;
}

.banner-open-close,
.banner-openclose {
	@extend .banner;
	background: $color-openclose;
}

.banner-cash {
	@extend .banner;
	background: $color-cash;
}

.banner-inventory {
	@extend .banner;
	background: $color-inventory;
}

.banner-timekeeping {
	@extend .banner;
	background: $color-timekeeping;
}

.banner-esmartclock {
	@extend .banner;
	background: $color-esmartclock;
}

.banner-settings-reporting {
	@extend .banner;
	background: $color-settings;
}

.banner-profit-loss {
	@extend .banner;
	background: $color-profitloss;
}

.banner-whats-new {
	@extend .banner;
	background: $color-whats-new;
}

.banner-ourlounge {
	@extend .banner;
	background: $color-ourlounge;
}

.banner-pta {
	@extend .banner;
	background: $color-pta;
}

.banner-chevron {
	color: $light-grey;
	font-size: 2.25rem !important;
	font-weight: 600;
	position: absolute;
	top: 8px;
	left: 20px;
}

.banner-text {
	color: $white;
	padding-top: 0px;
	@media (max-width: $phone) {
		font-size: 1.5rem;
	}
}

.banner-text-secondary {
	font-size: 1rem;
	color: $light-grey;
	margin-bottom: 0px;
}

.content-main {
	margin-bottom: 50px;
	@media (max-width: 630px) {
		margin-top: 175px;
	}
}

.section-title {
	margin-bottom: 20px;
	color: $black;
}

.section-title-homepage {
	margin-bottom: 15px;
	color: $dark-grey;
	font-style: italic;
}

.card-featured {
	@supports (display: grid) {
		display: grid;
		//grid-column-gap: 15px;
		grid-template-columns: auto 1fr;
		grid-template-rows: auto;
		grid-template-areas: "title-desc card-chevron";
		margin-bottom: 15px;
	}
	display: block;
	width: 100%;
	min-height: 110px;
	padding: 15px;
	border: none;
	border-radius: 7px;
	box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
	margin-bottom: 15px;
	h1 {
		font-size: 1.25rem;
		font-weight: $font-weight-base;
		margin: 0px auto 5px auto;
	}
	p {
		font-size: 0.85rem;
		margin: 0px auto;
		font-style: italic;
		color: $dark-grey;
	}
	@media (max-width: 991px) {
		min-height: 60px;
		h1 {
			font-size: 1rem;
		}
		p {
			display: none;
		}
		.title-desc {
			height: 30px;
			vertical-align: center;
		}
	}
	.title-desc {
		//float: left;
		display: inline;
	}
	.card-chevron {
		font-size: 2rem;
		font-weight: 600;
		text-align: right;
		margin-top: -15px;
		svg {
			width: 15px;
			height: 15px;
		}
	}
	&.openclose {
		border: 3px solid $color-openclose;
		background: rgba($color-openclose, 0.05);
		h1,
		.card-chevron path {
			fill: $color-openclose;
			color: $color-openclose;
		}
	}
	&.timekeeping {
		border: 3px solid $color-timekeeping;
		background: rgba($color-timekeeping, 0.05);
		h1,
		.card-chevron path {
			fill: $color-timekeeping;
			color: $color-timekeeping;
		}
	}
	&.inventory {
		border: 3px solid $color-inventory;
		background: rgba($color-inventory, 0.05);
		h1,
		.card-chevron path {
			fill: $color-inventory;
			color: $color-inventory;
		}
	}
	&.firsts {
		border: 3px solid $color-firsts;
		background: rgba($color-firsts, 0.05);
		h1,
		.card-chevron path {
			fill: $color-firsts;
			color: $color-firsts;
		}
	}
}

.card-primary {
	@supports (display: grid) {
		display: grid;
		grid-column-gap: 15px;
		grid-template-columns: 35px auto 35px;
		grid-template-rows: auto;
		grid-template-areas: "img p span";
		align-items: center;
	}
	padding: 15px;
	margin-top: 10px;
	border: none;
	border-radius: 7px;
	box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
	img {
		width: 35px;
		height: 35px;
		float: left;
		margin-right: 10px;
	}
	.icon {
		text-align: right;
	}
	svg {
		max-width: 20px;
		max-height: 20px;
		path {
			fill: $white;
		}
	}
	&.subcat {
		background-color: $light-grey;
		border-color: $mid-grey;
	}
	&.firsts {
		background-color: $color-firsts;
		border-color: $mid-grey;
	}
	&.openclose {
		background-color: $color-openclose;
		border-color: $mid-grey;
	}
	&.cash {
		background-color: $color-cash;
		border-color: $mid-grey;
	}
	&.inventory {
		background-color: $color-inventory;
		border-color: $mid-grey;
	}
	&.timekeeping {
		background-color: $color-timekeeping;
		border-color: $mid-grey;
	}
	&.esmartclock {
		background-color: $color-esmartclock;
		border-color: $mid-grey;
	}
	&.settings {
		background-color: $color-settings;
		border-color: $mid-grey;
	}
	&.profitloss {
		background-color: $color-profitloss;
		border-color: $mid-grey;
	}
	&.pta {
		background-color: $color-pta;
		border-color: $mid-grey;
	}
	&.ourlounge {
		background-color: $color-ourlounge;
		border-color: $mid-grey;
	}
	p.card-text-primary {
		color: $white;
		font-weight: $font-weight-base;
		font-size: 1.2rem;
		text-align: left;
		margin-bottom: 0;
	}
	p.card-text-secondary {
		font-weight: $font-weight-base;
		font-size: 1rem;
		margin-bottom: 0;
		background: none;
		&.openclose {
			color: $color-openclose;
		}
		&.cash {
			color: $color-cash;
		}
		&.inventory {
			color: $color-inventory;
		}
		&.firsts {
			color: $color-firsts;
		}
		&.settings {
			color: $color-settings;
		}
		&.profitloss {
			color: $color-profitloss;
		}
		&.ourlounge {
			color: $color-ourlounge;
		}
		&.pta {
			color: $color-pta;
		}
	}
	.card-chevron {
		font-size: 2rem;
		font-weight: 600;
		position: absolute;
		right: 30px;
		top: 30%;
		color: $white;
		&.subcat {
			color: $dark-grey;
			svg {
				path {
					fill: $dark-grey;
				}
			}
		}
	}
}

hr {
	margin: 10px;
}

.best-practices {
	background: $yellow;
	padding: 30px;
	box-shadow: 0 0 15px $mid-grey;
	min-height: 170px;
}

.triangle {
	&.left {
		float: right;
		margin-bottom: 30px;
		font-size: 0px;
		line-height: 0%;
		width: 0;
		border-top: 20px solid $yellow;
		border-bottom: 20px solid transparent;
		border-left: 40px solid transparent;
		border-right: 40px solid $yellow;
	}
	&.right {
		float: left;
		margin-bottom: 30px;
		font-size: 0px;
		line-height: 0%;
		width: 0;
		border-top: 20px solid $yellow;
		border-bottom: 20px solid transparent;
		border-left: 40px solid $yellow;
		border-right: 40px solid transparent;
	}
}

ul.checklist {
	font-size: 1rem;
	clear: both;
	margin-left: 0;
	padding-left: 0;
	margin-top: 15px;
	margin-bottom: 15px;
	line-height: 1.5em;

	a,
	.anc {
		color: $black;
		padding-left: 15px;
	}
	li {
		text-align: left;
	}
}

ul.checklist-subpage {
	clear: both;
	margin-left: 0px;
	line-height: 1.5em;
	li {
		display: block;
		list-style-type: none;
		background: url(#{$homepath}images/icon-checkmark1.svg) no-repeat left center;
		background: url(#{$homepath}images/icon-checkmark1.png) no-repeat left top;
		background-size: 30px 40px;
		padding: 0 15px 25px 50px;
	}
}

ul.links {
	@extend ul, .checklist;
	li {
		display: block;
		list-style-type: none;
		background: url(#{$homepath}images/icon-cursor.svg) no-repeat left center;
		background: url(#{$homepath}images/icon-cursor.png) no-repeat left top;
		background-size: 30px 30px;
		padding: 0 15px 15px 40px;
		sup {
			font-size: 0.5rem;
		}
	}
}

.related-topics {
	padding: 15px;
	margin-bottom: 30px;
	background: lighten($light-grey, 3%);
	border: 1px $mid-grey solid;
	border-radius: 10px;
	z-index: 1020;
	ul {
		margin-left: -30px;
	}
	li {
		list-style: none;
		padding-bottom: 0;
		padding-top: 10px;
	}
	a,
	.anc {
		color: $red;
		&:hover {
			text-decoration: underline;
		}
	}
	img {
		height: 45px;
		width: auto;
		padding-bottom: 10px;
	}
	h6 {
		font-weight: $font-weight-bold;
		color: $black;
	}
}

.icon-bullet {
	padding-bottom: 30px;
	h4,
	h5 {
		font-weight: $font-weight-base;
	}
	&.small {
		font-size: 100%;
		/* fixes bootstrap issue */
		img {
			float: left;
			width: 30px;
			margin-right: 15px;
			height: auto;
		}
		h4,
		h5 {
			padding-top: 2px;
		}
	}
	&.medium {
		img {
			float: left;
			width: 40px;
			margin-right: 15px;
			height: auto;
		}
		h4,
		h5 {
			padding-top: 3px;
		}
	}
	&.large {
		img {
			float: left;
			width: 50px;
			margin-right: 15px;
			height: auto;
		}
		h3,
		h4,
		h5 {
			padding-top: 8px;
		}
	}
	&.red {
		h4,
		h5 {
			color: $red;
		}
	}
	&.dark-grey {
		h4,
		h5 {
			color: $dark-grey;
		}
	}
	p {
		padding-right: 20px;
		line-height: 1.6em;
		padding-top: 5px;
		padding-bottom: 15px;
		&.aligned {
			padding-left: 45px;
		}
		&.indented {
			padding-left: 60px;
		}
		.screenshot {
			width: auto;
			float: none;
			max-width: 100%;
		}
	}
	ul {
		list-style: normal;
		clear: both;
		padding-bottom: 30px;
		li {
			padding: 10px 0;
		}
	}
}

.text-link {
	font-style: italic;
}

.icon-link {
	width: 30px;
	margin-right: 15px;
}

.topics-icon-checklist {
	width: 30px;
	position: relative;
	float: left;
	left: -15px;
}

.topics-item-checklist {
	padding-top: 5px;
	padding-bottom: 30px;
	padding-left: 35px;
	margin-right: -10px;
}

.topics-heading {
	color: $black;
	font-weight: $font-weight-medium;
	margin-bottom: 30px;
	padding-bottom: 5px;
	line-height: 1.4em;
	font-size: 1.3rem;
	border-bottom: 1px solid $light-grey;
	img {
		height: 50px;
		margin-left: 0;
		padding: 0;
	}
}

.topics-note {
	font-size: 0.8rem;
	padding-top: 5px;
	margin-bottom: 0;
}

.screenshot {
	margin: 0 auto;
	text-align: center;
	max-width: 650px;
	height: auto;
	align-self: center;
	margin-bottom: 50px;
	.img-desc {
		font-size: 0.8rem;
		font-style: italic;
	}
	&.border {
		border: 1px solid #ccc;
	}
	@media (max-width: $tablet) {
		width: 100%;
	}
}

.prev-next {
	width: 100%;
	margin: 0 auto;
	padding: 30px 0px;
	text-align: right;
	background: $white;
	li {
		display: inline-grid;
		list-style: none;
		margin: 2px;
	}
	a.previous:hover:before {
		content: "\f100";
		font-family: FontAwesome;
		font-size: 0.9rem;
		font-weight: normal;
		font-style: normal;
		margin: 0px 10px 0px 0px;
		text-decoration: none;
	}
	a.next:hover:after {
		content: "\f101";
		font-family: FontAwesome;
		font-size: 0.9rem;
		font-weight: normal;
		font-style: normal;
		margin: 0px 0px 0px 10px;
		text-decoration: none;
	}
	@media (max-width: $tablet) {
	}
}

/**** SEARCH ****/

.tipue_search_form {
	padding: 0 0 0 0 !important;
	margin: 0;
}

#tipue_search_input {
	margin: 0;
	font-size: 1rem;
	padding: 5px 5px 4px 30px;
	border: 1px solid $light-grey;
	background: url("#{$homepath}images/icon-search.svg") $white no-repeat;
	background: url("#{$homepath}images/icon-search.png") $white no-repeat;
	background-size: 20px 20px;
	background-position: 5px;
}

.tipue_search_clear {
	position: absolute;
	margin-left: 5px;
	margin-top: 3px;
	font-size: 1.5rem;
	color: #ccc;
}

#tipue_search_results_count {
	font-weight: 1.2em;
	color: $black;
	padding-bottom: 30px;
}

.tipue_search_content_title {
	font-size: 1.2rem;
	padding-top: 0.5em;
	a,
	.anc {
		color: $red;
		text-decoration: underline;
		&:hover,
		&:visited,
		&:active,
		&:focus {
			color: $red;
		}
	}
}

.tipue_search_content_url {
	a,
	.anc {
		color: $red;
		&:hover,
		&:visited,
		&:active,
		&:focus {
			color: $red;
		}
	}
}

.tipue_search_content_text {
	padding-top: 1.5em;
	padding-bottom: 1.5em;
	border-bottom: 1px solid $light-grey;
}

.tipue_search_content_bold {
	background: lighten($yellow, 30%);
	color: $black;
	padding: 5px;
}

.tipue_search_related_cols {
	padding: 0 15px 15px 15px; //float: left;
	//width: auto;
	background: $light-grey;
}

.tipue_search_related_title {
	//float: left;
	//width: auto;
	font-weight: $font-weight-bold;
	background: $light-grey;
	padding: 15px;
	&:after {
		content: ": ";
	}
}

.tipue_search_related_bold {
	color: $red;
	font-style: italic;
}

a.tipue_search_related {
	color: $black;
	&:hover,
	&:active,
	&:focus,
	&:visited {
		color: black;
		cursor: pointer;
	}
}

.tipue_search_related_text {
	width: auto;
	display: inline-block;
	margin-right: 15px;
	margin-bottom: 5px;
	padding: 5px 10px;
	background: $yellow;
}

#tipue_search_foot {
	text-align: center;
	#tipue_search_foot_boxes {
		list-style: none;
		li {
			display: inline-block;
			padding: 15px;
			&.current {
				font-weight: $font-weight-bold;
				color: $dark-grey;
			}
			a,
			.anc {
				color: $red;
				text-decoration: underline;
			}
			a:hover,
			a:active,
			a:focus {
				color: $red;
				cursor: pointer;
			}
		}
	}
}

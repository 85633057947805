.checklistReportTaskModal {
	display: flex;
	flex-direction: column;
	gap: 0.5rem;
	border-top: 1px solid #ddd;
	padding-top: 1.5rem;
	.item {
		.label {
			all: unset;
			color: #333;
			font-weight: bold;
		}
		.value {
			&.blue-title {
				font-weight: bold;
				color: var(--headings-color);
			}
		}
	}
	.status-area {
		display: flex;
		justify-content: space-between;
		gap: 1rem;
		flex-wrap: wrap;
		border-radius: 0.375rem;
		background-color: #f6f6f6;
		padding: 0.75rem;
		.label {
			all: unset;
			color: #333;
			font-weight: bold;
		}
	}
	.button-area {
		display: flex;
		justify-content: flex-end;
		gap: 1rem;
		border-top: 1px solid #ddd;
		padding: 0.75rem;
	}
}

@media screen and (min-width: 510px) {
	.checklistReportTaskModal {
		.status-area {
			min-width: 40rem;
		}
	}
}

@import "_variables.scss";

/* PAGE CONTENT */

.quiz-title {
	color: $white;
}

.padded {
	padding: 30px 30px; /* use this to add padding back to rows */
}

.last {
	/* use this to add padding to last row so it's not covered by footer */
	padding-bottom: 100px;
	@media (max-width: $tablet) {
		padding-bottom: 150px;
	}
}

/*Set page backgrounds & font-color for each category*/

.cash {
	background: $color-cash;
	color: $white;
}

.inventory {
	background: $color-inventory;
	color: $white;
}

.timekeeping {
	background: $color-timekeeping;
	color: $white;
}

.esmartclock {
	background: $color-esmartclock;
	color: $white;
}

.coming-soon {
	background: $light-grey;
	color: $mid-grey;
}

.settings-reporting {
	background: $color-settings;
	color: $white;
}

/* Cards */

.flashcard {
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0 15px;
	margin: 15px 0;
	height: 200px;
	//box-shadow: 0px 0px 20px $mid-grey;
	border: 1px solid $mid-grey;
	border-radius: 3px;
	> div {
		width: 100%;
	}
}

.cat {
	font-size: 1.5rem;
	text-align: center;
	&:hover {
		text-decoration: none;
		color: $white;
	}
}

.qa {
	color: $black;
	background: $white;
	height: auto;
}

.intro {
	text-align: left;
}

.question {
	font-weight: $font-weight-bold;
	text-align: center;
	h1 {
		font-size: 1.3rem;
		font-weight: 600;
		text-align: left;
		padding: 10px 15px;
		margin: 0 -15px;
		border-top-right-radius: 3px;
		border-top-left-radius: 3px;
		text-shadow: 1px solid $mid-grey;
		text-shadow: 0 0.5px 0 $black;
		p {
			font-size: 1rem;
			font-weight: $font-weight-base;
		}
		ol {
			li {
				font-size: 1rem;
				line-height: 1.3rem;
				font-style: italic;
				font-weight: $font-weight-base;
			}
		}
	}
}

ul.instructions {
	font-size: 0.9rem;
}

.choices {
	list-style: none;
	font-weight: $font-weight-base;
	text-align: left;
	padding: 0;
	//margin-left: -40px;
	a,
	.anc {
		color: $black;
	}
	li {
		display: block;
		padding: 15px 15px;
		border-bottom: 1px solid aliceblue;
	}
}

.mythbuster {
	.h1 {
		background: $black;
	}
	.choices {
		margin: 0 -15px;
		display: flex;
		flex-direction: row;
	}
	a,
	.anc {
		font-size: 2em;
		text-align: center;
		width: 50%;
		&.a {
			background-color: $success;
		}
		&.b {
			background-color: $failure;
		}
		li {
			border-bottom: 0;
			padding: 0;
			font-size: 4rem;
			color: $white;
		}
	}
}

.correct {
	h2 {
		color: $success;
		text-align: center;
	}
	p {
		color: $black;
		text-align: center;
	}
}

.wrong {
	h2 {
		color: $failure;
		text-align: center;
	}
	p {
		color: $black;
		text-align: center;
	}
}

.flip-back {
	font-size: 0.8rem;
	position: absolute;
	top: 10px;
	left: 10px;
	svg {
		max-width: 15px;
		max-height: 15px;
		path {
			color: $dark-grey;
		}
	}
}

.exit {
	//display: inline-block;
	float: left;
	color: white;
	padding: 5px 15px;
	background: $red;
	border-radius: 3px;
	&:hover {
		color: white;
		text-decoration: none;
	}
}

.done {
	text-align: center;
	h2 {
		color: $success;
	}
	h3 {
		color: $black;
	}
}

.next-question {
	float: right;
	color: white;
	padding: 5px 15px;
	background: $red;
	border-radius: 3px;
	&:hover {
		color: white;
		text-decoration: none;
	}
}

.quiz-completed {
	position: absolute;
	top: 30px;
	right: 30px;
	width: 20px;
	height: auto;
}

/** ALL VARIABLES **/
@import "variables";

/** LIBRARIES ***/

@import "_bootstrap-min.scss";
@import "_animate.scss";
@import "_jquery.multiselect.scss";

@import "_bootstrap-overrides.scss";
/** COMPONENT STYLES ***/

@import "_navbar.scss";
@import "_onboarding.scss";
@import "_app-switcher.scss";
@import "_super-select.scss";
@import "_breadcrumbs.scss";
@import "_sidebar.scss";
@import "_footer.scss";
@import "components/footer/debugger";
@import "dashboard-menu";
@import "sidebar-menu";
@import "_buttons.scss";
@import "_accordion.scss";

/** ICONS **/
@import "~material-design-icons/iconfont/material-icons.css";

/** SHARED STYLES ON ALL APPS **/
html,
body {
	font-size: $font-size-base;
}
.main {
	padding-top: 30px;
	padding-bottom: 60px;
	height: calc(100vh - var(--marginTop) - var(--marginBottom));
	overflow-y: auto;
}

@media screen and (max-width: 512px) {
	.main {
		padding-top: 10px;
		padding-bottom: 50px;
		height: calc(100vh - var(--marginTop) - var(--marginBottom));
		overflow-y: auto;
	}
}

.padded {
	padding: 30px;
}

.padding-bottom {
	padding-bottom: 15px;
}

.padding-top {
	padding-top: 15px;
}

.section-grey {
	background: $light-grey;
}

.page-header {
	// margin-top: 10px;
	margin: 15px 0;
	border-bottom: 1px solid $mid-grey;
}

a.anchor {
	display: block;
	position: relative;
	top: -60px;
	visibility: hidden;
}

.anc {
	cursor: pointer;
	&:hover {
		text-decoration: underline;
	}
}

video {
	max-width: 100%;
	max-height: 100%;
	background: #dedede;
	border: 1px solid #dedede;
}

ul {
	padding-block-end: 1rem;
}
ul li {
	padding-block-end: 1rem;
}

.icon-paragraph {
	max-width: 20px;
	max-height: 20px;
}
.icon-bullet {
	h4,
	h5 {
		font-weight: $font-weight-base;
	}

	&.small {
		font-size: 100%;

		/* fix bootstrap issue */
		img {
			float: left;
			width: 30px;
			margin-right: 15px;
			height: auto;
		}

		h4 {
			padding-top: 2px;
		}

		h5 {
			padding-top: 2px;
		}
	}

	&.medium {
		img {
			float: left;
			width: 40px;
			margin-right: 15px;
			height: auto;
		}

		h4 {
			padding-top: 3px;
		}

		h5 {
			padding-top: 3px;
		}
	}

	&.large {
		img {
			float: left;
			width: 50px;
			margin-right: 15px;
			height: auto;
		}

		h3 {
			padding-top: 8px;
		}
	}

	&.red {
		h4 {
			color: $red;
		}

		h5 {
			color: $red;
		}
	}

	&.dark-grey {
		h4 {
			color: $dark-grey;
		}

		h5 {
			color: $dark-grey;
		}
	}

	p {
		padding-right: 20px;
		line-height: 1.6em;
		padding-top: 10px;

		&.indented {
			padding-left: 60px;
		}
	}

	ul {
		list-style: normal;
		clear: both;
		padding-bottom: 30px;

		li {
			padding: 10px 0;
		}
	}
}

// ul {
// padding-inline-end: 15px;
// }

ul.inline-icons {
	list-style: none;
	margin-left: -40px;

	li {
		display: inline-block;
		margin-right: 15px;

		div {
			float: left;
			width: 30px;
			height: 30px;
			padding: 5px;
			text-align: center;
			color: $white;
			border-radius: 100%;
			svg {
				max-width: 20px;
				max-height: 20px;
				path,
				circle,
				rect {
					fill: $white;
				}
			}
			&.info {
				background: #7f1780;
			}

			&.cautions-warnings {
				background: #de3d15;
			}

			&.tips-best-practices {
				background: #dd9b1b;
			}

			&.coaching {
				background: #3557a8;
			}
		}

		p {
			float: left;
			padding-left: 10px;
		}
	}
}

ul.steps-list,
ol.steps-list {
	clear: both;
	margin-left: -40px;
	line-height: 1.5em;

	li {
		font-size: 1rem;
		display: block;
		list-style-type: none;
		padding: 0 15px 30px 50px;

		&:nth-child(1) {
			background: url("#{$homepath}images/icon-1C.svg") no-repeat left center;
			background: url("#{$homepath}images/icon-1C.png") no-repeat left top;
			background-size: 30px 30px;
		}

		&:nth-child(2) {
			background: url("#{$homepath}images/icon-2C.svg") no-repeat left center;
			background: url("#{$homepath}images/icon-2C.png") no-repeat left top;
			background-size: 30px 30px;
		}

		&:nth-child(3) {
			background: url("#{$homepath}images/icon-3C.svg") no-repeat left center;
			background: url("#{$homepath}images/icon-3C.png") no-repeat left top;
			background-size: 30px 30px;
		}

		&:nth-child(4) {
			background: url("#{$homepath}images/icon-4C.svg") no-repeat left center;
			background: url("#{$homepath}images/icon-4C.png") no-repeat left top;
			background-size: 30px 30px;
		}

		&:nth-child(5) {
			background: url("#{$homepath}images/icon-5C.svg") no-repeat left center;
			background: url("#{$homepath}images/icon-5C.png") no-repeat left top;
			background-size: 30px 30px;
		}

		&:nth-child(6) {
			background: url("#{$homepath}images/icon-6C.svg") no-repeat left center;
			background: url("#{$homepath}images/icon-6C.png") no-repeat left top;
			background-size: 30px 30px;
		}

		&:nth-child(7) {
			background: url("#{$homepath}images/icon-7C.svg") no-repeat left center;
			background: url("#{$homepath}images/icon-7C.png") no-repeat left top;
			background-size: 30px 30px;
		}

		&:nth-child(8) {
			background: url("#{$homepath}images/icon-8C.svg") no-repeat left center;
			background: url("#{$homepath}images/icon-8C.png") no-repeat left top;
			background-size: 30px 30px;
		}

		&:nth-child(9) {
			background: url("#{$homepath}images/icon-9C.svg") no-repeat left center;
			background: url("#{$homepath}images/icon-9C.png") no-repeat left top;
			background-size: 30px 30px;
		}

		&:nth-child(10) {
			background: url("#{$homepath}images/icon-10C.svg") no-repeat left center;
			background: url("#{$homepath}images/icon-10C.png") no-repeat left top;
			background-size: 30px 30px;
		}

		ul {
			margin-top: 30px;
			list-style: none;

			li {
				background: none;

				&:nth-child(1),
				&:nth-child(2),
				&:nth-child(3),
				&:nth-child(4),
				&:nth-child(5),
				&:nth-child(6),
				&:nth-child(7),
				&:nth-child(8),
				&:nth-child(9),
				&:nth-child(10) {
					background: none;
				}
			}
		}
		ul.hyphen-list {
			li {
				padding-left: 0;
			}
		}

		p {
			padding-bottom: 15px;
			margin-bottom: 0;

			&:last-of-type {
				margin-bottom: 15px;
			}
		}
	}

	&.split {
		img {
			padding-bottom: 50px;
		}

		@media (max-width: $tablet) {
			img {
				padding: 30px 0;
			}
		}
	}
}

.hyphen-list {
	list-style: none;

	li {
		padding-left: 0;
		background: none;
		text-indent: -20px;
		padding-bottom: 1.2em !important;
	}

	li:before {
		content: "-";
		padding-right: 10px;
		text-align: right;
	}
}

ul.doclist {
	clear: both;
	margin-left: -40px;
	margin-bottom: 20px;
	line-height: 1.6em;

	li {
		display: block;
		list-style-type: none;
		background: url("#{$homepath}images/icon-agreement.svg") no-repeat left center;
		background: url("#{$homepath}images/icon-agreement.png") no-repeat left top;
		background-size: 25px;
		padding: 0 15px 30px 35px;
		font-weight: $font-weight-bold;
		font-size: 1rem;
	}

	&.indented {
		margin-left: 0;
	}

	a:hover {
		text-decoration: none;
	}
}

ul.checklist {
	font-size: 1rem;
	clear: both;
	margin-left: 0;
	padding-left: 0;
	margin-top: 15px;
	margin-bottom: 15px;
	line-height: 1.5em;

	a,
	.anc {
		color: $black;
		padding-left: 15px;
	}
	li {
		text-align: left;
	}
}

ul.checklist-checklist {
	@extend ul, .checklist;

	li {
		display: block;
		list-style-type: none;
		background: url(#{$homepath}images/icon-checklist.svg) no-repeat left center;
		background: url(#{$homepath}images/icon-checklist.png) no-repeat left top;
		background-size: 30px 40px;
		padding: 7px 15px 25px 50px;
	}
}

ul.checklist-checkmark {
	@extend ul, .checklist;

	li {
		display: block;
		list-style-type: none;
		background: url(#{$homepath}images/icon-checkmark1.svg) no-repeat left center !important;
		background: url(#{$homepath}images/icon-checkmark1.png) no-repeat left top !important;
		background-size: 30px 25px !important;
		padding: 0 15px 30px 50px !important;

		span {
			display: block;
			font-size: 0.8rem;
			line-height: 1.4em;
			padding-top: 7px;
		}
	}
}

ul.checklist-firsts {
	@extend ul, .checklist;

	li {
		display: block;
		list-style-type: none;
		background: url(#{$homepath}images/laygo-icons/icon-laygo-firsts-secondary.svg) no-repeat left
			center;
		background: url(#{$homepath}images/laygo-icons/icon-laygo-firsts-secondary.png) no-repeat left
			top;
		background-size: 30px 30px;
		padding: 2px 15px 10px 50px;
	}
}

.checklist-multiline {
	margin: 20px 0px 20px 0px;
	font-size: 1rem;
}

.card {
	position: relative;
	display: block;
	height: auto;
	margin-top: 15px;
	//box-shadow: 0px 0px 20px $mid-grey;
	border: 1px solid $mid-grey;
	border-radius: 5px;

	h1 {
		font-size: 1.05rem;
		font-weight: 600;
		text-align: left;
		margin: 0;
		padding: 10px 15px;
		// border-top-right-radius: 3px;
		// border-top-left-radius: 3px;
		text-shadow: 1px solid $mid-grey;
		text-shadow: 0 0.5px 0 $black;
		display: flex;
		width: 100%;

		&.primary {
			font-size: 1.2rem;
		}

		&.initiative {
			padding: 10px 15px;
		}
	}

	h2 {
		font-size: 1.1rem;
		font-weight: 600;
		text-align: left;
		margin: 0;
		padding: 10px 15px;
		border-top-right-radius: 3px;
		border-top-left-radius: 3px;
		text-shadow: 1px solid $mid-grey;
		text-shadow: 0 0.5px 0 $black;
		display: block;
		width: 100%;
	}

	h3 {
		font-size: 1rem;
		font-weight: 500;
		text-align: left;
		margin: 0;
		padding: 10px 15px;
		border-top-right-radius: 3px;
		border-top-left-radius: 3px;
		text-shadow: 1px solid $mid-grey;
		text-shadow: 0 0.5px 0 $black;
		display: block;
		width: 100%;
	}
	h4 {
		font-size: 1rem;
		font-weight: 500;
		text-align: left;
		margin: 0;
		padding: 10px 15px;
		border-top-right-radius: 3px;
		border-top-left-radius: 3px;
		text-shadow: 1px solid $mid-grey;
		text-shadow: 0 0.5px 0 $black;
		display: block;
		width: 100%;
	}

	.heading {
		background: $black;
		color: $white;
		margin: 0 auto;
		//min-height: 4.5rem;
		font-size: 1.2rem;
		padding: 15px;
		text-align: center;
	}
	.data {
		text-align: center;
		font-size: 5rem;
	}

	.task-group-progress {
		position: absolute;
		top: 10px;
		right: 15px;
		text-align: right;
		color: $white;
		line-height: 0.8rem;
		font-weight: $font-weight-bold;

		span {
			font-weight: $font-weight-base;
			font-size: 70%;
		}
	}

	.card-content {
		display: block;
		background: transparent;
		min-height: 220px;
		padding: 15px;
		text-align: left;

		&.static {
			min-height: auto;

			ul {
				margin-left: 0;

				li {
					padding: 5px 0 20px 45px;
				}
			}
		}

		ul {
			/* for demo walkthroughs */
			padding-left: 0;
			list-style: none;

			li {
				padding: 5px 0;
				color: $black;

				a,
				.anc {
					color: $black;

					img {
						display: none;
					}

					i,
					svg {
						display: inline-block;
						font-size: 1.2rem;
						margin-right: 2px;
						margin-left: 2px;
						max-height: 18px;
						max-width: 18px;
					}
				}
			}

			.completed-task {
				list-style: none;

				a,
				.anc {
					i,
					svg {
						display: none;
					}

					img {
						display: inline-block;
						width: 20px;
						height: auto;
					}

					font-style: italic;
					opacity: 0.7;
				}
			}
		}

		.active {
			color: $black;
			font-size: 1.5rem;
			margin-bottom: 0;
			line-height: inherit;
		}

		.inactive {
			color: $dark-grey;
			font-size: 1.5rem;
			margin-bottom: 0;
			line-height: inherit;
			font-style: italic;
			font-weight: 300;
		}

		.current-task {
			padding-left: 35px;

			p {
				padding: 0;
			}

			.card-done {
				text-align: center;
				margin-left: -35px;
				font-style: italic;

				.card-badge {
					width: 100px;
					height: auto;
					text-align: center;
					padding: 10px 5px 20px 5px;
				}
			}

			.mark-complete {
				font-size: 1rem;
				font-size: 1.2rem;
				color: $dark-grey;
				text-align: right;

				svg {
					float: left;
					height: 20px;
					margin-left: -30px;
					margin-right: 10px;
				}
			}

			.task-type {
				font-weight: $font-weight-bold;
				font-size: 1rem;
				line-height: 1.25rem;
			}

			.task-title {
				display: block;
				font-size: 1.15rem;
				line-height: 1.5rem;
			}

			.task-time {
				font-size: 0.8rem;
				font-style: italic;
			}

			.task-description {
				display: block;
				font-size: 0.85rem;
				line-height: 1.1rem;
				font-style: italic;
			}

			input {
				width: unset;
				float: left;
				margin-right: 10px;
			}

			a.btn {
				display: block;
				font-weight: 400;
				//margin: 30px 15px 15px -15px;
				margin: 30px 0px 15px -35px;

				&.cash {
					background: $color-cash;
					color: $white;
				}

				&.inventory {
					background: $color-inventory;
					color: $white;
				}

				&.timekeeping {
					background: $color-timekeeping;
					color: $white;
				}

				&.esmartclock {
					background: $color-esmartclock;
					color: $white;
				}

				&.profitloss {
					background: $color-profitloss;
					color: $white;
				}

				&.other {
					background: $color-other;
					color: $white;
				}
			}

			li {
				background-size: 20px 20px;
			}
		}

		.static {
			h5 {
				font-size: 0.9rem;
				line-height: 1.3rem;
				font-style: italic;
				color: $black;
				font-weight: $font-weight-base;
			}

			h6 {
				font-weight: $font-weight-bold;
				padding: 10px 0 0 0;
			}

			p {
				font-size: 1rem;
				line-height: 1.5rem;
			}

			ul {
				list-style: circle;
				margin: 0;
				padding-left: 35px;

				li {
					padding: 0 5px 0 5px;
				}
			}

			a.btn {
				display: block;
				font-weight: 400;
				font-size: 1.2rem;
				background: $red;
				color: $white;
				margin: 15px;
			}

			img {
				padding-bottom: 20px;
			}

			@media (max-width: 992px) {
				img {
					max-width: 100px;
				}
			}
		}

		// &.initiative {
		//     text-align: center;

		//     img {
		//         width: 100px;
		//         height: 100px;
		//         border: 1px solid $light-grey;
		//         border-radius: 50%;
		//         margin: 0 auto;
		//     }

		//     ul.links {
		//         margin: 0;

		//         li {
		//             width: 100%;
		//             text-align: left;
		//             border-bottom: 1px solid $light-grey;

		//             img {
		//                 display: inline-block;
		//                 height: 30px;
		//                 width: auto;
		//                 margin-right: 5px;
		//                 border-radius: 5px;
		//             }

		//             span {
		//                 float: right;
		//             }
		//         }
		//     }
		// }
	}
	&.initiative {
		min-height: 360px;
		h1 {
			font-size: 28px;
			a,
			.anc {
				color: $white;
				flex: auto;
			}
			.whats-new {
				font-size: 1rem;
				color: $white;
				// position: absolute;
				// right: 15px;
				// top: 18px;
				text-decoration: underline;
				font-weight: normal;
				text-align: right;
				flex: auto;
			}
		}

		.card-content {
			background: transparent;
		}
	}
	.technology {
		min-height: 360px;
		text-align: center;
		background: transparent;
		img {
			max-width: 200px;
			border: 1px solid $light-grey;
			border-radius: 100%;
		}
	}
	.card-footer {
		height: 40px;
		padding: 10px 15px;

		.counter {
			color: $black;
			width: 50%;
			float: left;
		}

		.view-all {
			color: $black;
			text-decoration: underline;
			width: 50%;
			float: left;
			text-align: right;

			.checklist-description {
				position: relative;
				z-index: 1030;
				background: $light-grey;
				font-style: italic;
				padding: 15px;
				font-size: 0.8rem;
				line-height: 1.2rem;
				margin: 5px 0px 5px 25px;
			}
		}
	}

	.all-tasks {
		padding: 10px 15px;
		border-top: 1px dashed $black;

		p {
			font-size: 0.9rem;
			line-height: 1.1rem;
			color: $black;
		}

		a,
		.anc {
			color: $black;
		}

		ul {
			list-style: none;
			padding-left: 0;
			//margin-left: -35px;

			li {
				font-size: 0.9rem;
				padding-left: 25px;
				padding-top: 5px;
				padding-bottom: 5px;
				a,
				.anc {
					text-decoration: underline;
				}
			}

			.completed-task {
				list-style: none;
				background: url(#{$homepath}images/icon-check1.svg) no-repeat left top !important;
				/* background: url(#{$homepath}images/icon-check1.png) no-repeat left top !important; commented out because it was doing somthing weird to the roundness of the cicle -- would have to maybe do the if SVG doesnt work type of code? */
				background-size: 16px 16px !important;
				padding: 0 0 10px 25px;

				a,
				.anc {
					font-style: italic;
					opacity: 0.7;
				}
			}
			.mark-complete {
				font-size: 0.8rem;
				line-height: 1rem;
				&.completed {
					display: none;
				}
			}
		}
	}
}

/*Set page backgrounds & font-color for each category*/

.cash {
	&.all-tasks,
	&.card-footer {
		background: lighten($color-cash, 30%);
	}
}

h1.cash {
	background: $color-cash;
	color: $white;
}

.inventory {
	&.all-tasks,
	&.card-footer {
		background: lighten($color-inventory, 30%);
	}
}

h1.inventory {
	background: $color-inventory;
	color: $white;
}

.timekeeping {
	&.all-tasks,
	&.card-footer {
		background: lighten($color-timekeeping, 30%);
	}
}

h1.timekeeping {
	background: $color-timekeeping;
	color: $white;
}

.esmartclock {
	&.all-tasks,
	&.card-footer {
		background: lighten($color-esmartclock, 20%);
	}
}

h1.esmartclock {
	background: $color-esmartclock;
	color: $white;
}

.profitloss {
	&.all-tasks,
	&.card-footer {
		background-color: lighten($color-profitloss, 50%);
	}
}

h1.profitloss {
	background: $color-profitloss;
	color: $white;
}

.other {
	&.all-tasks,
	&.card-footer {
		background: lighten($color-other, 60%);
	}
}

h1.other {
	background: $color-other;
	color: $white;
}

.pta {
	&.all-tasks,
	&.card-footer {
		background: lighten($color-pta, 60%);
	}
}

h1.pta {
	background: $color-pta;
	color: $white;
}

.ourlounge {
	&.all-tasks,
	&.card-footer {
		background: lighten($color-ourlounge, 60%);
	}
}

h1.ourlounge {
	background: $color-ourlounge;
	color: $white;
}

h1.scheduling {
	background: $color-scheduling;
	color: $white;
}

p.black-text {
	color: $black;
	text-align: left;
}

.update-notification-container {
	position: fixed;
	left: 15px;
	right: 15px;
	z-index: 9999999;
	box-shadow: 5px 5px 10px $mid-grey;
	margin: 15px;
	border: 2px solid $success;
	border-radius: 10px;
	background: lighten($success, 45%);

	.message {
		color: $success;
	}

	&.failure {
		background: $failure;
		border: 2px solid $failure;

		.message {
			color: $white;
		}
	}

	$nSpacing: 80px;

	&.notification-0 {
		top: 0px;
	}

	&.notification-1 {
		top: $nSpacing;
	}

	&.notification-2 {
		top: calc(#{$nSpacing} * 2);
	}

	&.notification-3 {
		top: calc(#{$nSpacing} * 3);
	}

	&.notification-4 {
		top: calc(#{$nSpacing} * 4);
	}

	&.notification-5 {
		top: calc(#{$nSpacing} * 5);
	}
}

.settings-question {
	font-size: 1rem;
	padding: 10px;
}

.switch {
	list-style: none;

	li {
		display: block;
	}

	.switch-on {
		padding: 3px;
		font-size: 2rem;
		color: $success;
	}

	.switch-off {
		padding: 3px;
		font-size: 2rem;
		color: $failure;
	}

	.label-on,
	.label-off {
		font-size: 0.8rem;
		color: lighten($black, 20%);
	}
}

#userSettings {
	.container {
		display: block;
		position: relative;
		padding-right: 55px;
		//margin-bottom: 12px;
		cursor: pointer;
		font-size: 1rem;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
		text-align: right;
		width: auto;
		float: right;

		/* Hide the browser's default radio button */
		input {
			position: absolute;
			opacity: 0;
			cursor: pointer;
		}

		/* Create a custom radio button */
		.checkmark {
			position: absolute;
			top: 0;
			right: 15px;
			height: 25px;
			width: 25px;
			background-color: #eee;
			border-radius: 50%;

			/* Create the indicator (the dot/circle - hidden when not checked) */
			&:after {
				content: "";
				position: absolute;
				display: none;
				/* Style the indicator (dot/circle) */
				top: 9px;
				left: 9px;
				width: 8px;
				height: 8px;
				border-radius: 50%;
				background: white;
			}
		}

		/* On mouse-over, add a grey background color */
		&:hover input ~ .checkmark {
			background-color: #ccc;
		}

		/* When the radio button is checked, add a blue background */
		input:checked ~ .checkmark {
			background-color: $success;
		}

		/* Show the indicator (dot/circle) when checked */
		input:checked ~ .checkmark:after {
			display: block;
		}
	}

	select {
		width: 100%;
		height: 35px;
		border: 1px solid $mid-grey;
		padding: 5px 15px;
		//margin: 10px;
	}

	input[type="submit"] {
		margin-top: 50px;
		width: auto;
	}
}

.wait {
	width: 100%;
	margin: 0 auto;
	position: relative;
	font-size: 2rem;
	color: $black;
	text-align: center;
}

.button-static {
	padding-top: 20px;
}

.inline-img-text {
	img {
		height: 25px;
		width: auto;
	}

	font-size: 1rem;
	line-height: 1.5rem;
	margin-bottom: 20px;
}

.last {
	margin-bottom: 50px;
}

.disabled {
	pointer-events: none;
	opacity: 0.5;
}

input {
	&.invalid {
		border: 1px solid #ff0000;
	}
	&:focus.invalid {
		outline: 2px solid #de3d15;
	}
}

.pagination-container {
	display: flex;
	flex-wrap: wrap;
	gap: 1rem;
	.pagination-item {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 30px;
		width: 30px;
		background-color: var(--links-color);
		color: white;
		border-radius: 100%;
		cursor: pointer;
		&.active {
			background-color: gray;
			pointer-events: none;
		}
	}
}
.pagination-info {
	font-style: italic;
	color: gray;
	margin-bottom: 1rem;
}

@media print {
	.no-print,
	.no-print * {
		display: none !important;
	}
}

//simple loader
.loader {
	border: 4px solid #f3f3f300;
	border-radius: 50%;
	border-top: 4px solid #e2e2e2;
	width: 32px;
	height: 32px;
	-webkit-animation: spin 2s linear infinite; /* Safari */
	animation: spin 2s linear infinite;
}

.spin {
	-webkit-animation: spin 2s linear infinite; /* Safari */
	animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
	0% {
		-webkit-transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
	}
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

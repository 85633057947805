/* GLOBAL OVERRIDES */

html {
	font-size: $font-size-base;
}

body {
	font-family: $base-font;
	font-weight: $font-weight-base;
	overflow-x: hidden;
	position: relative;
	background: $white;
}

h1,
h2 {
	color: $red;
	font-family: $base-font;
	font-weight: $font-weight-extrabold;
}

h3,
h4,
h5,
h6 {
	color: $black;
	font-family: $base-font;
	font-weight: $font-weight-base;
	padding-bottom: 10px;
}

h1 {
	font-size: 2rem;
}

h2 {
	font-size: 1.83rem;
}

h3 {
	font-size: 1.75rem;
	line-height: 1.85rem;
}

h4 {
	font-size: 1.5rem;
	line-height: 1.5rem;
}

h5 {
	font-size: 1.25rem;
	line-height: 1.5rem;
}

h6 {
	font-size: 1rem;
}

p {
	font-size: 1rem;
	line-height: 1.5rem;
	margin-bottom: 1.3rem;
	&.small {
		font-size: 0.85rem;
		font-style: italic;
	}
	&.xs {
		font-size: 0.75rem;
		line-height: 1.2rem;
		font-style: italic;
		color: $dark-grey;
	}
}

a,
.anc {
	color: $link;
	&:hover {
		color: $link-hover;
		text-decoration: none;
	}
	&:focus {
		color: $link-focus;
		text-decoration: none;
	}
}

strong {
	font-weight: $font-weight-extrabold;
}

.btn-primary,
a.btn-primary {
	background: $red;
	color: $white;
	text-transform: uppercase;
	border-radius: 10px;
	border: none;
	padding: 15px 30px;
	margin-top: 15px;
	margin-bottom: 15px;
	&:hover,
	&:active,
	&:focus {
		background: darken($red, 10%);
		text-decoration: none;
	}
}

.btn-default,
a.btn-default {
	background: $white;
	color: $black;
	text-transform: uppercase;
	border-radius: 10px;
	border: none;
	padding: 15px 30px;
	margin-top: 15px;
	margin-bottom: 15px;
	&:hover,
	&:active,
	&:focus {
		background: $white;
		text-decoration: none;
	}
}

.btn-table,
a.btn-table {
	background: $blue;
	font-size: 0.75rem;
	text-align: center;
	display: block;
	color: $white;
	text-transform: uppercase;
	border-radius: 10px;
	border: none;
	padding: 10px 20px;
	margin-top: 10px;
	margin-bottom: 10px;
	&:hover,
	&:active,
	&:focus {
		background: darken($blue, 10%);
		text-decoration: none;
	}
}

img {
	/* this makes images more responsive and eliminates overflow */
	max-width: 100%;
	height: auto;
	&.padded {
		padding: 50px 15px 0px 15px;
	}
	&.full-width {
		width: 100%;
	}
}

blockquote {
	border: 0;
	font-size: 1rem;
}

input,
textarea {
	border: 1px solid $yellow;
	border-radius: 2px;
	padding: 15px 15px 15px 15px;
	width: 100%;
	margin-bottom: 30px;
	z-index: 10;
	background: transparent;
	font-weight: 400;
	color: $black;
	font-size: 1.2rem;
	line-height: 2rem;
}

input[type="checkbox"],
input[type="radio"] {
	display: inline;
}

input[type="checkbox"],
input[type="radio"] {
	width: auto;
	margin-right: 5px;
}

label {
	display: inline;
}

ul,
ol {
	li {
		font-size: 1rem;
	}
}

.modal {
	overflow-y: auto;
	z-index: 9999;
}

.row-equalize {
	display: flex;
	flex-wrap: wrap;
}

.container-fluid.main.formDetail {
	padding: 0;
}
.container-fluid.main.formDetail .formDetail {
	display: grid;
	grid-template-areas:
		"Header"
		"Content"
		"Footer";
	grid-template-rows: 132px auto 124px;
	height: 100%;
}
.container-fluid.main.formDetail .formHeader {
	grid-area: Header;
}

.container-fluid.main.formDetail .formContent {
	grid-area: Content;
}

.container-fluid.main.formDetail .formFooter {
	grid-area: Footer;
	border-top: 1px solid gray;
}

.form-progress-bar-container {
	display: flex;
	bottom: 3rem;
	left: 0;
	width: 100%;
	padding-left: 0.5rem;
	margin-right: 1rem;
}

.form-progress-bar {
	width: 100vw;
	max-width: calc(100% - 0.5rem);

	height: 10px;
	background-color: #eee;
	border-radius: 4px;
	overflow: hidden;
	//position: fixed;
}
.progress-bar-line {
	height: 100%;
	background-color: #4caf50;
}
@media (max-width: 600px) {
	.form-progress-bar {
		padding-right: 0.5rem;
	}
}

.expectationsAndRoutinesArea {
	display: flex;
	flex-direction: column;
	padding-left: 5px;
	justify-content: center;
	padding: 0;
	padding-top: 10px;
	font-weight: 700;
}

.completed-section {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	margin-top: 10px;
	flex-wrap: wrap;
}

.completed-info {
	display: flex;
	flex-direction: row;
	align-items: left;
	margin-bottom: 10px;
	font-weight: bold;
	font-size: 13px;
	gap: 1rem;
	flex-wrap: wrap;
}

.completed-info div {
	margin-bottom: 15px;
}

.review-update-button {
	background-color: var(--button-links-bg);
	color: white;
	padding: 10px;
	font-size: 0.75rem;
	cursor: pointer;
	border: none;
	align-self: flex-end;
	font-size: small;
}

.review-update-button {
	background-color: var(--button-links-bg);
	color: white;
	padding: 10px;
	cursor: pointer;
	border: none;
	border-radius: 5px;
	transition: font-weight 0.3s ease;
	&.feature-text-show {
		background-color: var(--button-links-bg);
		cursor: not-allowed;
		font-weight: bold;
	}
}
.history-table {
	width: 100%;
	border-collapse: collapse;
	margin-top: 20px;
	margin-bottom: 20px;
}

.history-table th,
.history-table td {
	border: 1px solid #ddd;
	padding: 8px;
	text-align: left;
}

.history-table th {
	background-color: #f2f2f2;
}

.history-table tbody tr:nth-child(even) {
	background-color: #f9f9f9;
}

.view-history-link {
	cursor: pointer;
	color: var(--links-color);
	&:hover {
		text-decoration: underline;
	}
}

.result {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-content: flex-start;
	margin: 0;
}
.search-form {
	button {
		display: flex;
		align-items: center;
		justify-content: center;
	}
}

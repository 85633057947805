@import "_variables.scss";
/*** IN-PAGE NAV ***/

.page-nav {
	&.nav {
		z-index: 99;
		width: 100%;
		margin-top: 10px;
		padding: 0 15px;
		background: $white;
		box-shadow: 1px 1px 5px $light-grey; //text-align: center;
		@media (max-width: $desktop) {
			width: 100%;
		}
		> li.title {
			font-weight: $font-weight-extrabold;
			text-align: left;
			padding: 15px;
			color: $red;
			@media (max-width: $tablet) {
				width: 100%;
				padding: 10px 15px 0 15px;
			}
		}
		li {
			display: inline-block;
			text-align: left;
			background: $white;
			padding-left: 0;
			.active {
				border-bottom: 5px solid $yellow;
				color: $black;
			}
			a:focus,
			a:hover,
			a:active {
				background: $white;
			}
			a.mobile-only {
				display: none !important;
				@media (max-width: 475px) {
					display: block !important;
				}
			}
			a.tablet-up {
				display: inline-block !important;
				@media (max-width: 475px) {
					display: none !important;
				}
			}
		}
	}
}

.affix {
	@media (min-width: $desktop) {
		width: 75%;
	}
}

.page-nav-offset {
	margin-top: 60px;
}

/* PAGE CONTENT */

section {
	padding-bottom: 30px;
	padding-top: 30px;
}

.padded-sub {
	padding: 30px 30px 0 30px;
}

.image-limit {
	max-width: 800px;
}

.section-midgrey {
	background: $mid-grey;
}

.sub-section {
	padding-top: 30px;
	padding-bottom: 30px;
	border-top: 1px dotted $mid-grey;
}

.section-bottommargin {
	margin-bottom: 35px;
}

.section-notopmargin {
	margin-top: 0px;
}

.section-bottommargin-more {
	margin-bottom: 75px;
}

.text-highlight-red {
	color: $red;
}

ol {
	margin-bottom: 30px;
}

ol.overview-list {
	li {
		@extend h4;
		color: $red;
		p {
			@extend h6;
			font-size: 0.8em;
		}
	}
}

.indented {
	padding-left: 30px;
}

.text-footnote {
	font-size: 0.6em;
	font-style: italic;
	text-align: right;
}

.figure {
	text-align: center;
	font-size: 0.8em;
}

.icon-index {
	width: 30%;
}

.icon-nav {
	float: left;
	height: 30px;
	width: auto;
	margin-right: 15px;
}

.best-bet,
.coming-soon {
	background: $yellow;
	padding: 30px;
	box-shadow: 0 0 15px $mid-grey;
	min-height: 170px;
}

.change-bubble {
	.question {
		@extend .best-bet;
		font-style: italic;
	}
}

.response {
	clear: both;
	padding-top: 30px;
}

.triangle {
	&.left {
		float: right;
		margin-bottom: 30px;
		font-size: 0px;
		line-height: 0%;
		width: 0;
		border-top: 20px solid $yellow;
		border-bottom: 20px solid transparent;
		border-left: 40px solid transparent;
		border-right: 40px solid $yellow;
	}
	&.right {
		float: left;
		margin-bottom: 30px;
		font-size: 0px;
		line-height: 0%;
		width: 0;
		border-top: 20px solid $yellow;
		border-bottom: 20px solid transparent;
		border-left: 40px solid $yellow;
		border-right: 40px solid transparent;
	}
}

ul.checklist {
	clear: both;
	margin-left: 0px;
	margin-bottom: 20px;
	line-height: 1.6em;
	li {
		display: block;
		list-style-type: none;
		background: url("#{$homepath}images/icon-checklist-sm.svg") no-repeat left center;
		background: url("#{$homepath}images/icon-checklist-sm.png") no-repeat left top;
		padding: 0 15px 30px 50px !important;
	}
	&.indented {
		margin-left: 30px;
	}
}

ul.starlist {
	clear: both;
	margin-left: -40px;
	margin-bottom: 20px;
	line-height: 1.6em;
	li {
		display: block;
		list-style-type: none;
		background: url("#{$homepath}images/icon-banner1.svg") no-repeat left center;
		background: url("#{$homepath}images/icon-banner1.png") no-repeat left top;
		background-size: 30px 35px;
		padding: 0 15px 30px 50px;
	}
	&.indented {
		margin-left: 30px;
	}
}

ul.answer {
	li {
		font-style: italic;
	}
}

ul.steps-list {
	clear: both;
	margin-left: -40px;
	margin-bottom: 50px;
	line-height: 2em;
	li {
		display: block;
		list-style-type: none;
		padding: 0 15px 35px 50px;
		&:nth-child(1) {
			background: url("#{$homepath}images/icon-1C.svg") no-repeat left center;
			background: url("#{$homepath}images/icon-1C.png") no-repeat left top;
			background-size: 35px 35px;
		}
		&:nth-child(2) {
			background: url("#{$homepath}images/icon-2C.svg") no-repeat left center;
			background: url("#{$homepath}images/icon-2C.png") no-repeat left top;
			background-size: 35px 35px;
		}
		&:nth-child(3) {
			background: url("#{$homepath}images/icon-3C.svg") no-repeat left center;
			background: url("#{$homepath}images/icon-3C.png") no-repeat left top;
			background-size: 35px 35px;
		}
		&:nth-child(4) {
			background: url("#{$homepath}images/icon-4C.svg") no-repeat left center;
			background: url("#{$homepath}images/icon-4C.png") no-repeat left top;
			background-size: 35px 35px;
		}
		&:nth-child(5) {
			background: url("#{$homepath}images/icon-5C.svg") no-repeat left center;
			background: url("#{$homepath}images/icon-5C.png") no-repeat left top;
			background-size: 35px 35px;
		}
		&.row {
			margin-left: 10px;
		}
	}
	li.checkmark {
		display: block;
		list-style-type: none;
		background: url("#{$homepath}images/icon-checklist-sm.svg") no-repeat left center;
		background: url("#{$homepath}images/icon-checklist-sm.png") no-repeat left top;
		padding: 0 15px 30px 50px;
	}
	&.nobottommargin {
		margin-bottom: 0px;
		li {
			padding: 0px 15px 0px 50px;
		}
	}
}

.question {
	font-weight: $font-weight-bold;
}

.answer {
	font-weight: $font-weight-base;
	font-style: italic;
	margin-top: -15px;
}

.before-after {
	display: flex;
	flex-wrap: wrap;
	margin: 15px auto;
	.before-after > [class*="col-"] {
		display: flex;
		flex-direction: column;
	}
	.illustration {
		width: 75px;
		max-width: 75px;
		margin: 0 auto;
		display: block;
	}
	.before {
		padding: 20px 15px;
		text-align: center;
		background: #efefef;
		border-top: 1px solid $mid-grey;
		border-right: 0;
		border-bottom: 1px solid $mid-grey;
		border-left: 1px solid $mid-grey;
		@media (max-width: $tablet) {
			border-right: 1px solid $mid-grey;
			border-bottom: 0;
		}
	}
	.arrow {
		width: 20px;
		height: auto;
		position: absolute;
		top: 0;
		bottom: 0;
		margin: auto 0;
		right: -19px;
		z-index: 50;
		@media (max-width: $tablet) {
			display: block;
			position: relative;
			right: 0;
			margin: 0 auto;
			margin-bottom: -50px;
			transform: rotate(90deg);
		}
	}
	.after {
		padding: 20px 15px 20px 30px;
		text-align: center;
		font-weight: 600;
		background: $white;
		border-top: 1px solid $mid-grey;
		border-right: 1px solid $mid-grey;
		border-bottom: 1px solid $mid-grey;
		border-left: 0;
		@media (max-width: $tablet) {
			border-left: 1px solid $mid-grey;
			border-top: 0;
			padding-left: 15px;
		}
	}
}

.btn-group-justified > .btn-group {
	background: $black;
	margin: 0 auto;
	padding: 15px;
	width: 100%;
	@media (max-width: 554px) {
		display: block;
		padding: 0;
	}
	> button,
	> .btn {
		background: $black;
		color: $white;
		border: 0;
		border-radius: 0;
		font-size: 1.2em;
		@media (max-width: 554px) {
			padding: 10px;
		}
		&.active,
		&:focus,
		&:active {
			background: $black;
			color: $white;
			font-weight: $font-weight-bold;
			box-shadow: none;
			span {
				border-bottom: 5px solid $yellow;
			}
		}
		&:hover {
			background: $black;
			color: $white;
			span {
				border-bottom: 5px solid $yellow;
			}
		}
	}
}

.tab-pane {
	h3 {
		color: $red;
		font-weight: $font-weight-bold;
	}
}

.screenshot {
	width: 100%;
	max-width: 800px;
	text-align: center;
	&.padded {
		padding-top: 0px;
		padding-bottom: 0px;
	}
}

.illustration {
	padding-top: 20px;
	padding-bottom: 30px;
	width: 100%;
	max-width: 1200px;
	text-align: center;
	margin: 0 auto;
	&.icon {
		width: 100%;
		max-width: 200px;
		padding-top: 15 px;
		padding-bottom: 30px;
	}
	&.bullet {
		width: 20px;
		padding: 0;
		margin-right: 10px;
	}
	&.list {
		width: 100%;
		max-width: 200px;
		padding-top: 0;
		padding-bottom: 0;
	}
}

.btn-table {
	max-width: 300px;
}

.table-responsive {
	border: none;
	table {
		border: none;
		thead tr th,
		tbody tr td {
			padding: 15px 10px;
		}
		thead tr th {
			border-bottom: 1px solid $black;
		}
		tbody tr td {
			background: $white;
		}
		td.cat {
			font-weight: $font-weight-bold;
			background: #dedede;
			color: $black;
			border-top: 1px solid $black;
			border-bottom: 1px solid $black;
		}
	}
	td.cat-region {
		font-weight: $font-weight-bold;
		background: #dedede;
		color: $black;
		border-top: 1px solid $black;
		border-bottom: 1px solid $black;
		width: 200px;
		@media (max-width: $tablet) {
			width: auto;
		}
	}
	td.cat-region-section {
		font-weight: $font-weight-bold;
		background: #dedede;
		color: $black;
		border-top: 1px solid $black;
		border-bottom: 1px solid $black;
		width: 250px;
		@media (max-width: $tablet) {
			width: auto;
		}
	}
	td.cat-alt {
		font-weight: $font-weight-bold;
		background: lighten(#dedede, 5%);
		color: $black;
		border-top: 1px solid $black;
		border-bottom: 1px solid $black;
	}
	td.cat-timeline {
		width: 200px;
		@media (max-width: $tablet) {
			width: auto;
		}
	}
	td.cat-copy {
		width: 200px;
		@media (max-width: $tablet) {
			width: auto;
		}
	}
	tr.last > td {
		border-bottom: 1px solid $black;
	}
	tr.last-region > td {
		border-bottom: 1px solid $black;
	}
}

@media (max-width: $tablet) {
	/* Force table to not be like tables anymore */
	table,
	thead,
	tbody,
	th,
	td,
	tr {
		display: block;
		border: none;
	}
	th {
		padding: 15px 0;
	}
	/* Hide table headers (but not display: none;, for accessibility) */
	thead tr {
		position: absolute;
		top: -9999px;
		left: -9999px;
	}
	tr {
		border-bottom: 1px solid $dark-grey;
	}
	tr.last {
		padding-bottom: 45px;
	}
	tr.last-region {
		padding-bottom: 0px;
		border-bottom: none;
	}
	tr.last > td {
		border-bottom: none;
	}
	td {
		/* Behave  like a "row" */
		border: 0;
		position: relative;
		padding: 10px;
		white-space: normal !important;
		text-align: left;
		word-break: normal;
		.old,
		.new {
			display: inline;
			font-weight: $font-weight-extrabold;
		}
	}
}

.modal-text {
	color: $blue;
	font-weight: $font-weight-bold;
}

.regional-timeline {
	max-width: 768px;
	height: auto;
}

.regional-timeline-section {
	float: right;
	max-width: 375px;
	height: auto;
	@media (max-width: $tablet) {
		float: none;
		max-width: 320px;
		height: auto;
		padding-bottom: 15px;
	}
}

.prev-next {
	width: 100%;
	margin: 0 auto;
	text-align: center;
	background: $white;
	li {
		display: inline-grid;
		list-style: none;
		margin: 2px;
	}
	a.previous:hover:before {
		content: "\f100";
		font-family: FontAwesome;
		font-size: 0.9em;
		font-weight: normal;
		font-style: normal;
		margin: 0px 10px 0px 0px;
		text-decoration: none;
	}
	a.next:hover:after {
		content: "\f101";
		font-family: FontAwesome;
		font-size: 0.9em;
		font-weight: normal;
		font-style: normal;
		margin: 0px 0px 0px 10px;
		text-decoration: none;
	}
}

.menu-content {
	li {
		&:hover {
			background: #222;
		}
		&.collapsed {
			+ .sub-menu {
				display: none;
			}
			.icon-chevron {
				transform: rotateX(0);
			}
		}
		&.active-section {
			+ .sub-menu {
				display: block;
			}
			.icon-chevron {
				transform: rotateX(180deg);
			}
		}
	}
}

.menu-list {
	.nav-section {
		.nav-section-header {
			background: #333;
			border-bottom: 1px solid #666;
			color: #fff;
			cursor: pointer;
			line-height: 35px;
			list-style: none;
			margin: 0;
			padding: 10px 15px;
			.icon-chevron {
				float: right;
				height: 15px;
				margin-left: 15px;
				margin-top: 10px;
				transform: rotateX(0);
				transition: rotateX 0.2s ease-in-out;
				width: 15px;
			}
			&.collapsed {
				.icon-chevron {
					transform: rotateX(180deg);
				}
			}
		}
		.sub-menu {
			display: flex;
			flex-direction: column;
			overflow: hidden;
			.page-link {
				color: #333;
				width: 100%;
				background-color: #fff;
				border: none;
				border-bottom: 1px solid #f6f6f6;
				line-height: 28px;
				margin-left: 0;
				padding: 10px 15px;
				cursor: pointer;
				&.active {
					background: #ffce00;
					font-weight: 600;
				}
				&:hover {
					background: #ffce00;
				}
			}
			&.collapse {
				display: none;
				&.in {
					display: flex;
				}
			}
		}
	}
}

.sections-container {
	section:nth-child(even) {
		background: #f6f6f6;
	}
}

.attention-block {
	//text-align: center;
	width: auto;
	height: auto;
	padding: 20px;
	margin: 20px auto;
	border-radius: 15px;
	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		font-weight: $font-weight-bold;
	}
	.super-list li {
		font-size: 1.8rem;
		color: white;
	}

	&.with-icon {
		border-radius: 15px;
		margin: 50px auto 50px 50px;
		.content {
			padding-left: 70px;
			margin-top: -50px;
		}
		.attention-icon {
			max-width: 100px;
			height: auto;
			position: relative;
			margin-top: -50px;
			margin-left: -50px;
		}
	}

	&.purple {
		h1,
		h2,
		h3,
		h4,
		h3,
		h4,
		h4,
		h6,
		p,
		ul,
		ol,
		blockquote,
		a {
			color: $white;
		}
	}
	&.red {
		h1,
		h2,
		h3,
		h4,
		h3,
		h4,
		h4,
		h6,
		p,
		ul,
		ol,
		blockquote,
		a {
			color: $white;
		}
	}
	&.tan {
		background: #d0cbbe;
	}
	&.green {
		h1,
		h2,
		h3,
		h4,
		h3,
		h4,
		h4,
		h6,
		p,
		ul,
		ol,
		blockquote,
		a {
			color: $white;
		}
	}
	&.blue {
		h1,
		h2,
		h3,
		h4,
		h3,
		h4,
		h4,
		h6,
		p,
		ul,
		ol,
		blockquote,
		a {
			color: $white;
		}
	}
	&.teal {
		h1,
		h2,
		h3,
		h4,
		h3,
		h4,
		h4,
		h6,
		p,
		ul,
		ol,
		blockquote,
		a {
			color: $white;
		}
	}
}

// .attention-image {
// 	max-width: 500px;
// 	height: auto;
// 	margin-bottom: 20px;
// 	display: block;
// 	margin-left: auto;
// 	margin-right: auto;
// }

// .attention-text {
// 	text-align: center;
// }

.reports-table {
	width: 100%;
	th,
	td {
		padding: 5px 15px;
	}
	th {
		background: $black;
		color: $white;
		.vars-more,
		.vars-less {
			font-size: 0.8rem;
			font-weight: $font-weight-base;
			color: $yellow;
			font-style: italic;
		}
	}
	td {
		border-bottom: 1px solid $light-grey;
		.vars-ellipses {
			font-weight: $font-weight-bold;
		}
	}
}

.info-page-links {
	display: flex;
	justify-content: center;
	gap: 1rem;
	margin-top: 1rem;
	flex-wrap: wrap;
	.info-page-link {
		display: flex;
		align-items: center;
		gap: 1rem;
		svg {
			width: 1.125rem;
			height: 1.125rem;
		}
	}
	.order-1 {
		order: 1;
	}
	.order-2 {
		order: 2;
	}
	.order-3 {
		order: 3;
	}
	.order-4 {
		order: 4;
	}
}

/* TOP NAV */

.navbar {
	background: $black;
	max-height: 50px;
	height: 50px;
	transition: 1s all ease-in-out;
	z-index: 9990;
	overflow: hidden;
}

.navbar-fixed-top {
	border: 0;
}

.navbar-header {
	padding-top: 0;
	padding-right: 0;
	padding-left: 0;
	position: absolute;
	right: 0;
	left: 80px;
}

.navbar-nav {
	margin: 0;
	width: 100%;
	padding: 0;
	li {
		font-size: 0.9375em;
		list-style: none;
		padding-left: 15px;
		max-width: 100%;
	}
	@media (max-width: $tablet) {
		padding: 0 0 100px 0;
		float: none !important;
	}
}

.navbar-hamburger {
	display: none;
	// @media (max-width: $desktop) {
	//     display: inline-block;
	//     float: left;
	//     padding: 10px;
	//     margin-left: 5px;
	//     margin-right: 5px;
	//     color: white;
	//     font-size: 1.2rem;
	//     border: none;
	//     height: 50px;
	//     background: $dark-grey;
	//     #hamburger {
	//         height: 35px;
	//         width: 35px;
	//         fill: $white;
	//     }
	//     #hamburgerClose {
	//         height: 35px;
	//         width: 35px;
	//         fill: $white;
	//     }
	// }
	// @media (max-width: $phablet) {
	//     font-size: 1rem;
	// }
}

.navbar-main-navigation {
	position: fixed;
	top: 50px;
	left: 65px;
	height: auto;
	overflow: hidden;
	z-index: 9999;
	.nav-list {
		display: block;
		min-width: 320px;
		ul {
			margin-left: -40px;
			li.active {
				background: $yellow;
				color: $black;
				font-weight: $font-weight-bold;
			}
			ul.nav-sub {
				background: $white;
				li {
					background-color: $white;
					border: none;
					line-height: 28px;
					border-bottom: 1px solid $light-grey;
					margin-left: 0px;
				}
				li a,
				li .anc {
					color: $black;
					display: block;
					&:hover {
						text-decoration: none;
					}
				}
				li.active {
					background: $yellow;
					font-weight: $font-weight-bold;
				}
				li.active a,
				li.active .anc {
					color: $black;
					&:hover {
						text-decoration: none;
					}
				}
				li:hover {
					background: lighten($yellow, 0.6);
				}
			}
			li {
				list-style: none;
				padding: 10px 15px;
				margin: 0;
				line-height: 35px;
				cursor: pointer;
				background: $dark-grey;
				color: $white;
				border-bottom: 1px solid #666;
				.icon-chevron {
					width: 15px;
					height: 15px;
					float: right;
					margin-left: 15px;
					margin-top: 10px;
					transform: rotateX(180deg);
					transition: 0.2s ease-in-out rotateX;
				}
				&.section-active {
					background: #ff9900;
				}
			}
			a,
			.anc {
				color: $white;
				&:hover {
					text-decoration: none;
				}
			}
		}
	}
}

.navbar-brand {
	color: $white;
	padding: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	line-height: 1.25rem;
	padding-left: 1rem;
	a,
	.anc {
		color: $white;
		font-size: 1.2rem;
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 0.5rem;
		&:hover {
			text-decoration: none;
		}
	}
	img {
		display: inline-block;
		width: auto;
		height: 30px;
		margin-right: 5px;
	}
	.brand-logo {
		background: linear-gradient(100deg, #00489c, #012855);
		padding: 0.25rem;
		width: 2.75rem;
		height: 2.75rem;
		border-radius: 100px;
	}
	@media (max-width: $tablet) {
		float: left;
		a,
		.anc {
			font-size: 1rem;
		}
	}
	@media (max-width: 410px) {
		display: none;
	}
}

ul.navbar-user {
	position: absolute;
	top: 0;
	right: 15px;
	list-style: none;
	margin: 0;
	padding-left: 0;
	li {
		color: $white;
		display: inline-block;
		height: 50px;
		text-align: center;
		padding: 0 2px;
		a,
		.anc {
			display: block;
			color: $white;
			min-width: 35px;
			height: 50px;
			padding: 10px;
			&:hover {
				text-decoration: none;
			}
			&.active {
				background: $yellow;
			}
			img {
				width: 25px;
				height: auto;
			}
		}
		&.navbar-n {
			a,
			.anc {
				padding-left: 12px;
			}
		}
	}
	@media (max-width: $phablet) {
		right: 30px;
		li {
			a,
			.anc {
				min-width: 25px;
				padding: 10px 3px;
				img,
				svg {
					width: 20px;
					height: auto;
				}
			}
		}
	}
	.navbar-l {
		color: $white;
		a.active-l,
		.anc.active-l {
			color: $yellow;
		}
	}
	.navbar-feedback {
		svg {
			width: 25px;
			width: 25px;

			path {
				fill: $white;
			}
		}
		&.full {
			svg {
				float: left;
				margin-right: 10px;
			}
			@media (max-width: 600px) {
				display: none;
			}
		}
		&.condensed {
			@media (min-width: 601px) {
				display: none;
			}
		}
	}
}

.notifications-number {
	position: absolute;
	top: 8px;
	right: 75px;
	width: 18px;
	height: 18px;
	border-radius: 50px;
	background: #bf0c0c;
	color: white;
	font-size: 0.6rem;
	padding: 2px;
	text-align: center;
	z-index: 1; //to keep it on top of bell
	@media (max-width: $phablet) {
		right: 44px;
	}
}

.search-dd {
	position: fixed;
	top: 50px;
	right: 15px;
	z-index: 99999;
	background: $yellow;
	padding: 15px 50px 15px 15px;
	border-radius: 5px;
	min-width: 320px;
	width: 60%;
	.search-form {
		input {
			background: $white;
			margin-bottom: 0;
			padding: 5px 15px;
			font-style: oblique;
		}
	}
	.search-close {
		position: absolute;
		top: 15px;
		right: 15px;
		font-size: 1.2rem;
		background: $red;
		padding: 2px 6px;
		svg {
			width: 15px;
			height: 15px;
			fill: $white;
		}
		a:hover,
		a:active,
		a:focus,
		a:visited {
			color: $white;
			cursor: auto;
		}
	}
	@media (max-width: $phablet) {
		right: 0;
		left: 0;
		width: 100%;
	}
}

.notifications-header {
	position: fixed;
	top: 50px;
	right: 0;
	z-index: 1040;
	.language {
		padding: 5px;
		.active {
			font-weight: $font-weight-bold;
		}
	}
	.box {
		background: #fff;
		width: 320px;
		@media (max-width: $phablet) {
			width: 90vw;
		}
		height: 100vh;
		padding: 15px;
		box-shadow: 1px 10px 10px rgba($black, 0.3);
		.panel-header {
			margin-top: -15px;
			padding: 10px 0;
			background: $yellow;
		}
		h1.panel-title {
			font-size: 1.3rem;
			color: $black;
		}
		.dismiss {
			text-align: right;
			svg {
				max-height: 18px;
				max-width: 18px;
				fill: $black;
			}
		}
		.scrollable {
			overflow: auto;
			padding-bottom: 100px;
		}
		.notification-item {
			clear: both;
			border-bottom: 1px solid $light-grey;
			h1.title {
				font-size: 1.1rem;
				color: $black;
				font-weight: $font-weight-medium;
				padding-bottom: 0.8rem;
				line-height: 1.5rem;
			}
			h2.date {
				font-size: 0.8rem;
				line-height: 1.5rem;
				font-weight: $font-weight-medium;
				color: $black;
				padding-bottom: 0.8rem;
			}
			.description {
				p {
					font-size: 0.875rem;
				}
				ul {
					font-size: 0.875rem;
					line-height: 1.5rem;
					margin-bottom: 1.3rem;
				}
				li {
					font-size: 0.875rem;
					line-height: 1.5rem;
				}
			}
			&:nth-child(even) {
				background: $light-grey;
			}
			&.no-new {
				padding: 15px;
			}
		}
		.notification-inactive {
			display: none;
		}
		.show-all {
			text-align: center;
			margin: 0 auto;
			width: 150px;
			.label {
				color: $black;
				font-weight: $font-weight-base;
				text-align: center;
				padding: 5px 10px;
				background: $light-grey;
				border-top-left-radius: 0;
				border-top-right-radius: 0;
				border-bottom-left-radius: 4px;
				border-bottom-right-radius: 4px;
			}
		}
	}
}

.last-notifications {
	margin-bottom: 200px;
}

.profile-links {
	position: fixed;
	right: 0;
	top: 50px;
	bottom: 40px;
	width: 250px;
	background: $white;
	box-shadow: 1px 10px 10px rgba($black, 0.3);
	z-index: 99999;
	.panel-header {
		background: $yellow;
		font-weight: $font-weight-bold;
		padding: 5px 15px;
		font-size: 1.3rem;
		.dismiss {
			float: right;
			svg {
				width: 18px;
				height: 18px;
				fill: $black;
			}
		}
	}
	.links {
		ul {
			margin-top: 5px;
			margin-left: -40px;
			list-style: none;
			li {
				padding: 10px 15px;
				border-bottom: 1px solid $light-grey;
			}
		}
		.logout {
			background: transparent;
		}
	}
}

#feedbackForm {
	width: 100%;
	max-width: 800px;
	margin: 0 auto;
	padding: 10px 15px;
	label {
		display: block;
		font-size: 0.8rem;
		font-weight: $font-weight-base;
		color: $dark-grey;
		width: 100%;
		clear: both;
	}
	input,
	textarea {
		border: 1px solid $yellow;
		border-radius: 2px;
		padding: 15px 15px 15px 15px;
		width: 100%;
		margin-bottom: 30px;
		z-index: 10;
		background: transparent;
		font-weight: $font-weight-base;
		color: $black;
		font-size: 1.2rem;
		line-height: 2rem;
		&::placeholder {
			color: $mid-grey;
		}
	}
	textarea {
		min-height: 200px;
	}
	button {
		float: right;
	}
}

.image-notification {
	width: 80%;
	margin-bottom: 20px;
}

.navbar-secondary {
	background: lighten($black, 10%);
	margin: 0 auto;
	position: fixed;
	border-radius: 0;
	top: 50px;
	left: 0;
	right: 0;
	z-index: 1030;
	ul {
		padding-left: 0;
		margin: 0;
		a,
		.anc {
			color: $white;
			li {
				display: inline-block;
				//border-right: 1px solid $white;
				padding: 12px;
				font-size: 1rem;
				@media (max-width: 990px) {
					padding: 10px;
					font-size: 0.8rem;
				}
				img {
					width: 20px;
					height: 20px;
					margin-right: 5px;
					@media (max-width: 990px) {
						margin: 0;
						width: 25px;
						height: 25px;
					}
				}
				&.active {
					font-weight: $font-weight-bold;
					border-bottom: 5px solid $yellow;
				}
			}
		}
	}
}

//this resizes the navbar when scrolling
.navbar-small {
	top: -50px;
	transition: 1s all ease-in-out;
}

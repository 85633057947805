.form-controls {
	display: flex;
	//bottom: 6px;
	width: 100%;
	padding: 0px 7px;
	font-size: small;
	justify-content: space-between;
}
.form-controls .pre-next {
	display: flex;
	gap: 1rem;
}
.form-controls button {
	background-color: var(--button-links-bg);
	color: white;
	border: none;
	border-radius: 8px;
	cursor: pointer;
	font-weight: bold;
}
.form-controls button.close-navBar {
	order: -1;
	background-color: white;
	color: black;
	border: 1px solid black;
}
.form-controls button:hover {
	background-color: gray;
}
.form-controls button:hover.next-navBar {
	background-color: gray;
}
.form-controls button.previous-navBar {
	margin-left: auto;
	border-radius: 5px;
}
.form-controls button.next-navBar {
	background-color: var(--button-links-bg);
	color: white;
	margin-left: auto;
	padding: 10px 10px;
	border-radius: 5px;
}

.loading-bar::after {
	content: " ";
	background: #ffc107;
	width: 50%;
	height: inherit;
	display: block;
	margin: 0;
	animation: loader 0.9s ease-in infinite;

	animation-duration: 0.9s;
	animation-timing-function: ease-in;
	animation-delay: 0s;
	animation-iteration-count: infinite;
	animation-direction: normal;
	animation-fill-mode: none;
	animation-play-state: running;
	animation-name: loader;
}

.loading-bar {
	background: #ffeeba;
	width: 100%;
	height: 4px;
	line-height: 0;
	display: block;
	margin: 0 auto;
	overflow: hidden;
	position: fixed;
	top: 50px;
	left: 0;
}

@keyframes loader {
	from {
		margin-left: -50%;
		opacity: 1;
	}
	to {
		margin-left: 100%;
		opacity: 0.7;
	}
}

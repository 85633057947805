.top-pdf-viewer {
	position: absolute;
	top: var(--marginTop);
	left: 0;
	width: 100%;
	height: 100dvh;
	z-index: 10;
	height: calc(100dvh - var(--marginTop) - var(--marginBottom));
	.tp-card {
		height: 100%;
		padding: 0;
	}
}

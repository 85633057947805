button {
	border-radius: 0.375rem;
	height: 2.625rem;
	padding-left: 0.75rem;
	padding-right: 0.75rem;
	font-weight: 600;
	&.outline {
		border: 1px solid #333;
		background: white;
	}
	&.default-red {
		color: white;
		background: var(--button-links-bg);
	}
	&:disabled {
		opacity: 0.7;
	}
}

.btn:focus,
.btn.focus {
	color: var(--button-links-bg);
	text-decoration: none;
}

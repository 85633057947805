.detailHeader-info {
	text-align: left;
	background-color: #f0f0f0;
	width: 100% !important;
	box-sizing: border-box !important;
	padding-left: 10px;
}

.detailHeader-info h1 {
	margin: 0;
	padding-top: 5px;
	font-size: 32px;
	font-weight: 700;
}

.detailHeader-info p {
	display: flex;
	flex-direction: column;
	align-items: left;
	margin: 0;
	font-weight: bold;

	span {
		font-weight: 700;
		font-size: 13px;
		line-height: 17.7px;
	}

	.thin {
		font-weight: 400;
	}
}

.detailHeader-info.formHeader {
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	height: 100%;
	padding-top: 5px;
	padding-bottom: 5px;
}

#titleForHeader {
	font-weight: 700;
	font-size: 24px;
	border-bottom: 1px solid #dddddd;
}

#FormItem-header {
	font-weight: 700;
	font-size: 16px;
	line-height: 21.79px;
	padding: 0px;
}
.formItem-inputBox {
	padding: 0px;
	border: 1px solid #dddddd;
	font-size: 14px;
	padding-left: 5px;
	margin: 0;
	width: auto;
}
.select-dropdown {
	display: flex;
	margin-bottom: 10px;
	flex-direction: column;
	width: fill;
	border: 1px solid #dddddd;
	border-radius: 3px;
}
select option {
	font-weight: 400px;
	font-size: 16px;
}
.input-container {
	display: flex;
	flex-direction: column;
}
.form-item-content {
	display: flex;
	flex-direction: column;
	padding: 10px;
}
.form-items {
	width: 100%;
	margin: 0px;
}
#textarea-inputbox {
	height: 100px;
	border-radius: 3px;
	border: 1px solid #dddddd;
	margin: 0;
	font-size: 13px;
	padding: 5px;
}

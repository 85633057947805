.not-accessible {
	display: flex;
	padding: 1rem;
	padding-top: 2rem;
	font-size: 1.25rem;
	justify-content: center;

	span {
		text-align: center;
	}
}

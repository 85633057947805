input[type="checkbox"] + label {
	width: 20px;
	height: 20px;
	border-radius: 50%;
	font-size: 16px;
	font-weight: 400;
	line-height: 24px;
	letter-spacing: 0em;
	text-align: left;
	a {
		color: #7daff4;
	}
}
.task-label {
	.anc {
		font-weight: 300;
		display: flex;
		gap: 0.5rem;
		align-items: center;
		// color: var(--headings-color);
		svg {
			width: 1.25rem;
			height: 1.25rem;
		}
	}
	&.critical {
		.anc {
			font-weight: bold;
		}
	}
}
.checkbox-rounded {
	border-radius: 50%;
	width: 1.3em;
	height: 1.3em;
	vertical-align: middle;
	outline: none;
}

.check-list-table-container {
	.checklist-row {
		vertical-align: top;
	}
	.checklist-table-element {
		display: flex;
		align-items: center;
		width: 100%;
		cursor: pointer;
		.checklist-check-icon {
			width: 1.8rem;
			height: 1.8rem;
			margin-right: 0.5rem;
			flex-shrink: 0;
			&.gray {
				color: gray;
			}
			&.yellow {
				color: var(--links-color);
			}
		}
		label {
			margin-bottom: 0;
		}
	}
}

[id^="accordion"],
.accordion {
	.panel-default {
		.panel-heading {
			background: $light-grey;
			padding-top: 0;
			padding-bottom: 0;
			h4 {
				padding: 10px 0;
			}
			a {
				color: $black;
			}
		}
		.panel-collapse {
			.panel-body {
				border-color: $light-grey;
			}
		}
	}
	mark {
		background-color: $yellow;
		padding: 0;
	}
}

/*** GLOBAL NAVBAR ***/

.app-switcher {
	display: block;
	float: left;
	height: 50px;
	img {
		margin: 0;
		padding: 0;
		width: 50px;
	}
	.slide-menu-control {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 50px;
		height: 50px;
	}
	.app-switcher-close-icon {
		font-size: 3rem;
		color: white;
		margin: 10px;
		background: $black;
		text-align: center;
		width: 35px;
		height: 35px;
		path {
			fill: $white;
		}
	}
	.app-switcher-app-icon {
		font-size: 3rem;
		color: white;
		background: $black;
		text-align: center;
		width: 35px;
		height: 35px;
		path {
			fill: $white;
		}
	}
}

.apps-links {
	position: fixed;
	text-align: left;
	left: 0px;
	top: 50px;
	bottom: 0;
	width: 275px;
	z-index: 99999;
	overflow: auto;
	background: #444;
	ul {
		padding-left: 0px;
		margin-bottom: 0;
		padding-inline-end: 0;
	}
	a,
	.anc {
		text-decoration: none;
	}
	li {
		list-style: none;
		font-size: 1rem;
		color: white;
		//padding-right: 30px;
		border-bottom: 2px solid #333;
		img {
			display: inline-block;
			max-width: 60px;
			margin-right: 10px;
			padding: 5px;
			border-radius: 10px;
		}

		&.header {
			padding: 15px 5px;
			color: $white;
			font-size: 1.3rem;
			background: $black;
			display: flex;
			gap: 10px;
			align-items: center;
			svg {
				display: inline-block;
				height: 15px;
				width: auto;
				fill: $white;
				padding-right: 5px;
			}
			&.sub {
				cursor: pointer;
				&:hover {
					background: #222;
				}
			}
		}
	}
	.anc li {
		padding: 10px 0;
		display: flex;
		align-items: center;
		&:hover {
			background: #333;
		}
	}
}

.fade-away {
	background: $white;
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	opacity: 0.5;
	width: 100vw;
	height: 100vh;
	z-index: 9980;
}

.mcd-info-nav {
	padding-left: 0px;
	font-size: 1rem;
	li.active {
		background: $yellow;
		color: $black;
		font-weight: $font-weight-bold;
	}
	ul.menu-content,
	ul.sub-menu {
		width: 100%;
		margin: 0;
	}
	ul.sub-menu {
		background: $white;
		li {
			background-color: $white;
			border: none;
			line-height: 28px;
			border-bottom: 1px solid $light-grey;
			margin-left: 0px;
		}
		li a,
		li .anc {
			color: $black;
			display: block;
			&:hover {
				text-decoration: none;
			}
		}
		li.active {
			background: $yellow;
			font-weight: $font-weight-bold;
		}
		li.active a,
		li.active .anc {
			color: $black;
			&:hover {
				text-decoration: none;
			}
		}
		li:hover {
			background: lighten($yellow, 0.6);
		}
	}
	li {
		list-style: none;
		padding: 10px 15px;
		margin: 0;
		line-height: 35px;
		cursor: pointer;
		background: $black;
		color: $white;
		border-bottom: 1px solid #666;
		.icon-chevron {
			width: auto;
			height: 20px;
			float: right;
			margin-left: 15px;
			margin-top: 10px;
			transform: rotateX(180deg);
			transition: 0.2s ease-in-out rotateX;
		}
		&.section-active {
			background: #ff9900;
		}
	}
	a,
	.anc {
		color: $white;
		&:hover {
			text-decoration: none;
		}
	}
	ul li {
		.anc {
			display: flex;
			justify-content: space-between;
			img {
				padding: 0;
			}
		}
	}
}

@media (min-width: 1200px) {
	.mcd-info-nav {
		.menu-list {
			display: none;
		}
	}
}

footer {
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
	width: 100%;
	height: 40px;
	margin: 0 auto;
	padding: 5px 15px;
	background: $black;
	color: $white;
	z-index: 999;
	p {
		margin-bottom: 0px;
		font-size: 0.8rem;
	}
	ul.role-switch {
		margin-top: -5px;
		margin-left: -45px;
		li {
			list-style: none;
			display: inline-block;
			padding: 5px 10px;
			text-align: center;
			font-size: 0.8rem;
			a,
			.anc {
				color: $white;
			}
			a:hover,
			.anc:hover {
				text-decoration: none;
			}
			&.active {
				border-top: 5px solid $yellow;
			}
		}
	}
}

.login {
	width: 100vw;
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
	.background {
		z-index: -1;
		position: absolute;
		width: 100%;
		height: 100%;
		background: linear-gradient(100deg, #00489c, #012855);
		.yellow-blocks {
			position: absolute;
			top: 100%;
			left: 50%;
			transform-origin: 0% 0%;
			transform: rotate(-60deg);
			.row {
				display: flex;
				gap: 1rem;
				width: calc(3 * 100vh);
				margin-bottom: 1rem;
				overflow: hidden;
				white-space: nowrap;
				animation: bannermove 20s linear infinite;
				&:nth-child(2) {
					animation: bannermove 30s linear infinite;
				}
				.block {
					background-color: #ffce00;
					border-radius: 8px;
					height: 200px;
					flex: 1 1 0;
				}
			}
		}
	}
	.log-area {
		.img-container {
			display: flex;
			flex-direction: column;
			align-items: center;
			color: white;
			gap: 0.5rem;
			padding-bottom: 0.5rem;
			color: whitesmoke;
			img {
				width: 6rem;
			}
		}
		.card {
			background-color: whitesmoke;
			margin: 1rem auto;
			width: 90%;
			max-width: 512px;
			padding: 1rem;
			border-radius: 1rem;
			input {
				border: 1px solid darkgray;
				border-radius: 4px;
				height: 54px;
			}
			button {
				border-radius: 4px;
				cursor: pointer;
				color: whitesmoke;
				width: 100%;
				padding: 0.5rem;
			}
			.login-btn {
				background-color: #a10400;
				display: flex;
				justify-content: center;
			}
			.eid-btn {
				background-color: #2a499b;
				margin-bottom: 1rem;
			}
			.separator {
				margin-top: 1rem;
				margin-bottom: 1rem;
				width: 100%;
				border-bottom: 1px solid darkgray;
			}
			.errorMsg {
				color: #a10400;
				font-style: italic;
				text-align: center;
				margin-bottom: 1rem;
			}
			.successMsg {
				color: darkgreen;
				font-style: italic;
				text-align: center;
				margin-bottom: 1rem;
			}
		}
	}
}

@keyframes bannermove {
	0% {
		transform: translate(0, 0);
	}
	100% {
		transform: translate(-50%, 0);
	}
}

// tablet
@media screen and (min-width: 512px) {
}

// desktop
@media screen and (min-width: 1100px) {
	.login {
		.background {
			.yellow-blocks {
				left: 60%;
				.row {
					width: calc(3 * 100vw);
					.block {
						height: 300px;
					}
				}
			}
		}
		.log-area {
			.img-container {
				img {
				}
			}
		}
	}
}

.g3-modal {
	// @apply fixed top-0 left-0 z-40 flex h-full w-full items-center justify-center;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 999999;
	display: flex;
	height: 100%;
	width: 100%;
	justify-content: center;
	align-items: center;
	.g3-card {
		// @apply flex h-full w-full max-w-full flex-col overflow-y-auto rounded-md text-gray-600;
		background: white;
		display: flex;
		height: 100%;
		width: 100%;
		max-width: 100%;
		flex-direction: column;
		overflow-y: auto;
		border-radius: 0.5rem;
		color: rgb(63, 63, 63);
		.g3-card-header {
			// @apply m-4 flex flex-row justify-between;
			margin: 1rem;
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			.close {
				// @apply mt-1 mr-1 w-max cursor-pointer self-end text-black opacity-60;
				margin-top: 0.5rem;
				margin-right: 0.5rem;
				width: max-content;
				cursor: pointer;
				opacity: 0.6;
				align-self: flex-end;
				justify-self: end;
				color: black;
			}
			.g3-modal-title {
				// @apply flex self-center text-xl font-bold;
				display: flex;
				align-self: center;
				font-size: 1.5rem;
				font-weight: bold;
			}
		}
		.g3-modal-content {
			// @apply flex flex-col overflow-y-auto px-0;
			display: flex;
			flex-direction: column;
			overflow-y: auto;
			padding-left: 0;
			padding-right: 0;
		}
	}
	&:before {
		content: "";
		width: 100%;
		height: 100%;
		background-color: rgba(0, 0, 0, 0.5);
		position: absolute;
		z-index: -1;
	}
}
@media screen and (min-width: 820px) {
	.g3-modal {
		.g3-card {
			max-height: 90%;
			width: fit-content;
			height: unset;
			// @apply mx-10;
			margin-left: 2rem;
			margin-right: 2rem;
		}
		.g3-card .g3-modal-content {
			// @apply px-7 pb-7;
			padding-left: 1.5rem;
			padding-right: 1.5rem;
			padding-bottom: 1.5rem;
		}
	}
}

body {
	overflow: auto;
}

.health-checks-table {
	width: 100%;
	border-collapse: collapse;
	margin-top: 1rem;
	padding-left: 10px;
	margin-bottom: 20px;

	th,
	td {
		border: none;
		padding: 10px;
		text-align: left;
	}

	th {
		border-bottom: 1px solid black;
	}

	tbody {
		tr:nth-child(even) {
			background-color: #f9f9f9; // even row color
		}

		tr:nth-child(odd) {
			background-color: #ffffff; // odd row color
		}

		tr:hover {
			background-color: #f5f5f5;
		}
	}
}

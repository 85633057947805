.pdf-viewer {
	padding: 0;
	height: 100%;
	background: #4b4b4b;
	display: flex;
	flex-direction: column;
	.pdf-control-panel {
		font-size: 16px;
		color: black;
		background-color: white;
		// border-radius: 5px;
		// width: fit-content;
		display: flex;
		width: 100%;
		height: 50px;
		overflow: hidden;
		border-bottom: 2px solid #333;
		justify-content: space-between;
		align-items: center;
		padding: 4px;

		[class*="-icon"] {
			width: 24px;
			height: 24px;
		}
		.page-control {
			display: flex;
			align-items: center;
			justify-content: space-between;
			gap: 8px;
			.input-c {
				display: flex;
				align-items: center;
				width: max-content;
				.page-input {
					width: 40px;
					// height: 50px;
					line-height: 1.5rem;
					padding: 5px;
					margin: 4px;
				}
				.pages {
					width: max-content;
					white-space: nowrap;
				}
			}
		}
		.zoom-control {
			display: flex;
			align-items: center;
			justify-content: space-between;
			gap: 8px;
			.zoom-out-icon,
			.zoom-in-icon {
				width: 28px;
				height: 28px;
			}
		}
		.close-control {
			display: flex;
			align-items: center;
			justify-content: space-between;
			.close-icon {
				width: 28px;
				height: 28px;
			}
		}
	}

	.react-pdf__Document {
		height: 100%;
		overflow: scroll;
		.react-pdf__Page__canvas {
			margin: 0 auto;
		}
	}

	.pdf-clickable {
		cursor: pointer;
	}

	.pdf-disabled {
		cursor: not-allowed;
		color: lightgray;
	}
}

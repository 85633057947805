@import "_variables.scss";
/* PAGE CONTENT */

.resource-sub-nav {
	position: relative;
	background: $light-grey;
	padding: 5px 15px;
	hr {
		margin: 0;
	}
}

#resourceItem {
	border: 1px solid $light-grey;
	border-bottom: 0;
	padding: 15px;
	overflow: hidden;
}
.featured-resource {
	position: absolute;
	top: 0px;
	right: 15px;
	font-size: 1.5rem;
	transform: rotate(45deg);
	max-width: 30px;
	max-height: 30px;
	path {
		fill: $yellow;
	}
}

.description {
	margin-top: 10px;
}

.resource-thumbnail {
	padding: 0;
	border: 0;
	border-radius: 3px;
	opacity: 0.75;
	min-width: 100%;
	min-height: 75px;
	background: #dedede;
	overflow: hidden;
	display: flex;
	justify-content: center;
	align-items: center;
}

.resource-thumbnail img {
	max-width: 60px;
	max-height: 60px;
	min-width: 25px;
	min-height: 25px;
}

.toggle-filters {
	font-size: 1.2rem;
	font-weight: bold;
	svg {
		max-width: 15px;
		max-height: 15px;
		path {
			fill: $red;
		}
	}
}

.resource-filters,
.resource-sort,
.resource-view {
	font-size: 0.8rem;
	padding-left: 0;
	@media (max-width: $tablet) {
		float: left;
		border-left: 1px solid #dedede;
		padding-left: 5px;
	}
	li {
		font-size: 0.8em;
		list-style: none;
		display: block;
		padding: 5px 10px;
		background: #dedede;
		margin-right: 10px;
		//margin-top: 3px;
		@media (max-width: $tablet) {
			display: inline-block;
		}

		svg {
			max-width: 10px;
			max-height: 10px;
			path,
			rect {
				fill: $red;
			}
		}
	}
	.title {
		font-size: 1em;
		font-weight: bold;
		list-style: none;
		display: block;
		padding: 10px 0;
		background: none;
		margin-right: 10px;
	}
}

.inline-link {
	padding-top: 40px;
	a,
	.anc {
		font-size: 1.5rem;
		font-weight: $font-weight-bold;
	}
	img {
		height: 75px;
		float: left;
		margin-top: -15px;
		margin-right: 15px;
		margin-bottom: 30px;
	}
}

.selected {
	color: $black;
	font-weight: bold;
	li {
		background: $yellow;
		svg {
			path,
			rect {
				fill: $black;
			}
		}
	}
}

.icon-back {
	max-width: 15px;
	max-height: 15px;
	path {
		fill: $red;
	}
}

.back-to-top {
	visibility: hidden;
	width: 40px;
	height: 40px;
	position: fixed;
	bottom: 75px;
	right: 15px;
	z-index: 999;
}

/* SIDEBAR */

.sidebar {
	position: fixed;
	//top: 40px; //this is handled by mcd-info.php
	bottom: 40px;
	left: 0;
	z-index: 1000;
	display: block;
	padding: 20px 0 140px 0;
	overflow-x: hidden;
	overflow-y: auto; /* Scrollable contents if viewport is shorter than content.*/
	background-color: $black;
	border-right: 1px solid $light-grey;
	@media (max-width: $desktop) {
		display: none;
	}
}

.panel-group {
	width: 100%;
	margin: 0 auto;
	.panel-nav {
		border: none;
	}
	.panel-heading {
		background: $yellow;
		color: $white;
		border-radius: 0;
		border: none;
		> .panel-title {
			font-weight: $font-weight-extrabold;
			color: $black;
			padding: 10px 0;
		}
	}
	a:hover {
		text-decoration: none;
	}
	a.collapsed > .panel-heading {
		border: none;
		background: $black;
		> .panel-title {
			color: $white;
			font-weight: $font-weight-bold;
		}
	}
	.panel-body {
		border: 1px solid $yellow;
	}
	.list-group {
		a,
		.anc {
			color: $black;
		}
		.active {
			color: $black;
			background: $yellow;
			font-weight: $font-weight-bold;
		}
	}
	.list-group-item {
		padding-bottom: 15px 15px;
		&:hover {
			background-color: $yellow;
		}
	}
	a.list-group-item-sub {
		li {
			padding-left: 40px;
		}
	}
}

/*** COLLAPSIBLE NAV **/

.menu-list {
	ul {
		margin-left: -40px;
		font-size: 1rem;
		li.active {
			background: $yellow;
			color: $black;
			font-weight: $font-weight-bold;
		}
		ul.sub-menu {
			background: $white;
			li {
				background-color: $white;
				border: none;
				line-height: 28px;
				border-bottom: 1px solid $light-grey;
				margin-left: 0px;
			}
			li a,
			li .anc {
				color: $black;
				display: block;
				&:hover {
					text-decoration: none;
				}
			}
			li.active {
				background: $yellow;
				font-weight: $font-weight-bold;
			}
			li.active a,
			li.active .anc {
				color: $black;
				&:hover {
					text-decoration: none;
				}
			}
			li:hover {
				background: lighten($yellow, 0.6);
			}
		}
		li {
			list-style: none;
			padding: 10px 15px;
			margin: 0;
			line-height: 35px;
			cursor: pointer;
			background: $black;
			color: $white;
			border-bottom: 1px solid #666;
			.icon-chevron {
				width: 15px;
				height: 15px;
				float: right;
				margin-left: 15px;
				margin-top: 10px;
				transform: rotateX(180deg);
				transition: 0.2s ease-in-out rotateX;
			}
			&.section-active {
				background: #ff9900;
			}
		}
		a,
		.anc {
			display: flex;
			justify-content: space-between;
			color: $white;
			&:hover {
				text-decoration: none;
			}
		}
	}
}

.rotate-180 {
	a img.icon-chevron {
		transform: rotateX(0deg);
		transition: 0.2s ease-in-out rotateX;
	}
}

.sidebar-menus {
	.dash-title {
		display: flex;
		align-items: center;
		gap: 0.5rem;
		color: white;
		font-weight: 800;
		font-size: 1.3rem;
		background: #333;
		padding: 15px 5px;
		&.clickable {
			cursor: pointer;
			&:hover {
				background: #1d1d1d;
			}
		}
	}
	.sidebar-menu {
		display: grid;
		grid-template-columns: 1fr;
		color: #ffffff;
		.menu-item {
			border-left: 4px solid #aaaaaa;
			border-bottom: 2px solid #333;
			display: flex;
			gap: 0.5rem;
			height: 5rem;
			overflow: hidden;
			cursor: pointer;
			.icon-slot {
				width: 3.5rem;
				flex-shrink: 0;
				display: flex;
				justify-content: center;
				align-items: center;
				img {
					width: 3rem;
					height: 3rem;
					object-fit: contain;
				}
			}
			.text-slot {
				flex: auto;
				display: flex;
				flex-direction: column;
				justify-content: center;
				line-height: 1.3;
				.title {
					font-weight: 700;
				}
				.description {
					font-size: 0.75rem;
					font-style: italic;
					margin-top: unset;
				}
			}
			&:hover {
				background-color: #3a3a3a;
			}
		}
	}
	&.root {
		.sidebar-menu {
			grid-template-columns: 1fr;
			.menu-item {
				padding: 0.5rem;
				text-align: center;
				min-height: 80px;
				.icon-slot,
				.arrow-slot {
					display: none;
				}
				.text-slot {
					font-size: 1.3rem;
					display: flex;
					justify-content: center;
					align-items: center;
					.description {
						font-style: normal;
					}
				}
			}
		}
	}
}

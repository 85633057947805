.healthChecksArea {
	display: flex;
	flex-direction: column;
	align-items: left;
	justify-content: center;
	padding: 0;
	font-weight: bold;
}

.healthChecks-completed-section {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	align-self: self-end;
}

.healthChecks-review-update-button {
	background-color: var(--button-links-bg);
	color: white;
	padding: 8px;
	font-size: 0.85rem;
	cursor: pointer;
	border: none;
	border-radius: 5px;
	transition: font-weight 0.3s ease;
	margin-left: 10px;
}

.healthChecks-review-update-button.feature-text-show {
	background-color: var(--button-links-bg);
	font-weight: bold;
}

.healthChecks-filter-box {
	background-color: transparent;
	border: 1px solid #ccc;
	padding: 6px;
	color: black;
	width: 200px;
	font-size: small;
	margin-right: 3px;
}
.healthChecks-header {
	padding: 0;
	padding-top: 15px;
}

.g3-pagination {
	display: flex;
	flex-wrap: wrap;
	color: rgb(51, 51, 51);
	align-items: center;
	gap: 1rem;
	.pagination-bar {
		display: flex;
		flex-wrap: wrap;
		.pagination-item {
			// @apply flex h-9 w-9 cursor-pointer items-center justify-center border-y border-l border-gray-400 text-xs;
			display: flex;
			height: 2.25rem;
			width: 2.25rem;
			cursor: pointer;
			align-items: center;
			justify-content: center;
			border-top: 1px solid lightgray;
			border-bottom: 1px solid lightgray;
			border-left: 1px solid lightgray;
			font-size: 0.75rem;
			line-height: 1rem;
			&:first-child {
				// @apply rounded-l;
				border-top-left-radius: 0.25rem;
				border-bottom-left-radius: 0.25rem;
			}
			&:last-child {
				// @apply rounded-r border-r;
				border-right: 1px solid lightgray;
				border-top-right-radius: 0.25rem;
				border-bottom-right-radius: 0.25rem;
			}
			&.active {
				// @apply pointer-events-none bg-fsOrange text-white;
				pointer-events: none;
				background-color: #ffce00;
			}
			&:hover {
				// @apply bg-fsOrange bg-opacity-20;
				background-color: #ffe785;
			}
			&.disabled {
				// @apply pointer-events-none bg-gray-200 text-black opacity-50;
				pointer-events: none;
				background-color: rgb(206, 206, 206);
				opacity: 0.5;
			}
		}
	}
	.jump-to-page {
		display: flex;
		align-items: center;
		gap: 0.5rem;
		input {
			-webkit-appearance: none;
			-moz-appearance: textfield;
			width: 3rem;
			height: 2.25rem;
			margin: 0;
			padding: 0;
			padding-left: 0.5rem;
			font-size: small;
		}
		button {
			display: flex;
			align-items: center;
			border-radius: 0.25rem;
			background: #ffce00;
			padding: 8px;
		}
	}
}

.checklist-reports {
	&.main {
		padding: 0;
	}
	.checklist-report-header {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		gap: 1rem;
		padding: 10px 15px;
		.checklist-select {
			min-width: 15rem;
			color: var(--headings-color);
			font-size: 20px;
			font-weight: 700;
			border: none;
		}
		.checklist-description {
			font-size: 1rem;
			font-weight: 600;
			padding-left: 0.5rem;
		}
	}
	.mobile-list-container {
		.mobile-list-item {
			display: flex;
			flex-direction: column;
			gap: 0.75rem;
			border-bottom: 1px solid #b1b1b1;
			padding: 10px 0;
			&:first-child {
				border-top: 1px solid #b1b1b1;
			}
			&:nth-child(even) {
				background-color: #f2f2f2;
			}
			.mobile-list-item-row {
				display: flex;
				.mobile-list-item-cell {
					width: 50%;
					max-width: 50%;
					text-align: left;
					font-size: 1rem;
					color: #000;
					&.label {
						font-weight: bold;
						white-space: unset;
					}
					&.value {
						&.anc {
							cursor: pointer;
							color: var(--links-color);
						}
					}
				}
			}
		}
	}
}

@media screen and (min-width: 510px) {
	.checklist-reports {
		&.main {
			padding: 30px 15px 60px;
		}
	}
}

.checklist-report-table-element,
.checklist-report-table-header {
	&.center {
		text-align: center;
	}
	&.orange {
		color: rgb(172, 117, 15);
	}
}
.checklist-report-table-header {
	border-bottom: 1px solid #000;
	margin-bottom: 10px;
}

.checklist-report-table {
	width: 100%;
	border-collapse: collapse;
}

.checklist-report-table th,
.checklist-report-table td {
	text-align: left;
	padding: 8px;
}

.checklist-report-table table {
	width: 100%;
	table-layout: fixed;
}

.checklist-report-table tr:nth-child(odd) td {
	background-color: #f2f2f2;
}

@media screen and (max-width: 768px) {
	.checklist-report-table td {
		width: 100%;
		display: block;
	}
}

.checklist-report-due-date-container {
	display: flex;
}

.checklist-report-due-date-container-items {
	margin-right: 0px;
}

.checklist-report-circle-container {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-right: 10px;
}
.checklist-report-circle {
	width: 12px;
	height: 12px;
	border-radius: 50%;
}

.clickable-element {
	cursor: pointer;
	color: var(--links-color); //styleName: Heading 1;
	&:hover {
		text-decoration: underline;
	}
}

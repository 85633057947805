@import "_variables.scss";

.lpr-tabs {
	margin-top: -62px;
	margin-right: 0;
	border-bottom: 1px solid $mid-grey;
	li a,
	li a:hover,
	li a:active {
		background: none;
		border-top: 0;
		border-right: 0;
		border-bottom: 0;
		border-left: 0;
	}
	li.active a,
	li.active a:hover,
	li.active a:active {
		color: $black;
		font-weight: $font-weight-bold;
		border-top-color: $mid-grey;
		border-right-color: $mid-grey;
		border-left-color: $mid-grey;
	}

	@media (max-width: $tablet) {
		margin-top: -21px;
		margin-bottom: 1rem;
		border-bottom: 0;
		float: right;
		li a,
		li a:hover,
		li a:active {
			background: none;
			border-top: 0;
			border-right: 0;
			border-bottom: 0;
			border-left: 0;
		}
		li.active a,
		li.active a:hover,
		li.active a:active {
			border-top: 0;
			border-bottom: 1px solid $mid-grey;
			border-top-left-radius: 0;
			border-top-right-radius: 0;
			border-bottom-left-radius: 3px;
			border-bottom-right-radius: 3px;
		}
	}
}

.task-tabs,
.nav-pills {
	padding-left: 0;
	margin-bottom: 20px;
	list-style: none;
	li {
		border-radius: 0;
		background: $white;
		padding: 0;
		border: 1px solid $mid-grey;
		text-align: center;
		a,
		.anc {
			display: block;
			width: 100%;
			padding: 5px;
			color: $black;
			font-weight: $font-weight-bold;
			&:hover,
			&:focus {
				background: $light-grey;
			}
		}
	}
	li.active {
		background: $yellow;
		color: $black;
		border: 1px solid $yellow;
		a,
		a:hover,
		a:focus,
		a:active,
		.anc,
		.anc:hover,
		.anc:focus,
		.anc:active {
			background: $yellow !important;
			color: $black !important;
		}
	}
}

/* what's new section */

.update-container {
	border-bottom: 2px solid $light-grey;
	padding-bottom: 15px;
	.update-date {
		text-align: center;
		margin-top: 40px;
		margin-bottom: 20px;
		color: black;
		font-weight: $font-weight-bold;
		color: $red;
	}
	.update-content {
		font-size: 0.85rem;
		padding: 15px 0;
		p {
			font-size: 0.85rem;
		}
		ul {
			padding-bottom: 15px;
		}
		ul > li {
			font-size: 0.85rem;
			line-height: 1.5rem;
		}
	}
}

.section-bottommargin {
	margin-bottom: 35px;
	line-height: 1.5rem;
}

.super-badge {
	max-width: 160px;
	margin: 0 auto;
	text-align: center;
}

.super-badge-count {
	position: absolute;
	left: 0;
	right: 0;
	top: 130px;
	font-size: 2rem;
	font-weight: bold;
	color: $black;
}

.super-badge-label {
	position: absolute;
	left: 0;
	right: 0;
	top: 170px;
	margin: 0 auto;
	font-size: 0.8rem;
	color: $black;
	font-weight: $font-weight-base;
}

ul.badges {
	list-style: none;
	padding-left: 0;
	width: 100%;
	margin: 0 auto;
	li {
		display: inline-block;
		margin-right: 5px;
		padding: 5px 0;
		//max-width: 120px;
		text-align: center;
		vertical-align: top;

		img {
			width: 60px;
			height: auto;
		}
		p {
			padding: 0 8px;
		}
	}
	&.my-badges {
		li {
			width: 160px;
			padding-bottom: 15px;
			p {
				margin-top: 15px;
				font-size: 0.9rem;
			}
		}
	}
}

.staff-roles {
	ul {
		padding-left: 0;
		list-style: none;
		li {
			padding: 15px;
		}
	}
	&.mobile {
		padding-bottom: 20px;
		ul {
			overflow: auto;
			li {
				float: none;
				display: inline-block;
			}
		}
	}
}

.staff-title {
	h5 {
		font-weight: 600;
		color: $red;
	}
}

/*
  Learning Path Report Css Start
*/
.store-table,
.report-table {
	.color-gold {
		color: $orange;
	}
	.color-red {
		color: $red;
	}
	.color-orange {
		color: $orange;
	}
	.color-green {
		color: $green;
	}
	.color-blue {
		color: $blue;
	}
	.color-gray {
		color: $mid-grey;
	}
}

.report-table {
	width: 100%;
	tr {
		th {
			border-right: 1px solid $light-grey;
			text-align: center;
		}
		td {
			&:first-child {
				border-right: none !important;
				a,
				.anc {
					color: $red;
					text-decoration: underline;
				}
			}
		}
	}
	.tr-first {
		th {
			border-top: 1px solid $light-grey;
			background: $black;
			color: $white;
			&:first-child {
				border-left: 1px solid $light-grey;
				border-right: none;
				text-align: left;
			}
			small {
				font-size: 0.8rem;
				font-weight: $font-weight-base;
			}
		}
	}
	.table-td-data {
		font-size: 1.2rem;
		font-weight: 600;
		text-align: center;
	}
	.table-th-uc {
		font-size: 0.75rem;
	}

	.table-td-data,
	.table-th-uc {
		.td-slash {
			font-weight: 100;
			padding-right: 3%;
		}
		.value-user {
			width: 45%;
			display: inline-block;
			text-align: center;
			font-size: 0.75rem;
		}
		.value-per {
			width: 45%;
			display: inline-block;
		}
	}
	.table-th-total {
		font-size: 1.5rem;
		vertical-align: bottom;
	}
}

.store-table {
	th,
	td {
		text-align: center;
	}
	.tr-first {
		th {
			border: none;
			padding: 5px;
			margin: 0;
			background: $black;
			color: $white;
			small {
				font-size: 0.8rem;
				font-weight: $font-weight-base;
			}
		}
	}
	.tr-second {
		th {
			font-weight: 100;
			background: darken($dark-grey, 10%);
			color: $white;
			&:first-child {
				text-align: left;
				font-weight: 600;
			}
		}
	}
	td {
		&:first-child {
			text-align: left;
		}
	}
}

table.dataTable.cell-border tbody tr td,
table.dataTable.cell-border tbody tr td:first-child {
	border-color: $light-grey;
}
table.dataTable.stripe tbody tr.odd,
table.dataTable.display tbody tr.odd,
table.dataTable.display tbody tr.odd > .sorting_1,
table.dataTable.order-column.stripe tbody tr.odd > .sorting_1 {
	background-color: $white;
}

table.dataTable.stripe tbody tr.even,
table.dataTable.display tbody tr.even,
table.dataTable.display tbody tr.even > .sorting_1,
table.dataTable.order-column.stripe tbody tr.even > .sorting_1 {
	background-color: $light-grey;
}

.lpr-page {
	.card {
		margin-bottom: 50px;
	}
	.icon-off {
		width: 30px;
		margin-right: 5px;
		vertical-align: text-bottom;
	}
}
.lpr-options {
	text-align: right;
	span {
		padding-right: 5px;
	}
}

.lpr-nsn-search-box {
	width: 125px;
	height: 35px;
}
.lpr-print-button,
.lpr-search-button {
	background: $red;
	border: 0;
	border-radius: 0;
	color: $white !important;
	display: inline-flex !important;
	gap: 10px;
	padding: 5px 10px;
	svg {
		width: 20px;
		height: 20px;
		path,
		rect,
		polygon {
			fill: $white;
		}
	}
	&:hover {
		background: $red;
		filter: brightness(0.9);
	}
	&:active,
	&:focus {
		outline: none !important;
	}
}

.icon-fav,
.icon-check,
.icon-x {
	width: 20px;
	height: 20px;
}

.icon-mid-grey {
	fill: $mid-grey;
}
.icon-yellow {
	fill: $yellow;
}
.icon-green {
	fill: $green;
}
.icon-red {
	fill: $red;
}

button.dt-button.buttons-print {
	display: none;
}

.dt-print-view {
	.icon-off {
		width: 30px;
		margin-right: 5px;
		vertical-align: text-bottom;
	}

	.report-table tr th {
		border-right: 1px solid black;
		border-top: 1px solid black;
		text-align: center;
	}
	.report-table tr th:first-child {
		border-left: 1px solid black;
		border-right: none;
	}

	h2,
	.card .heading,
	.card .data {
		font-size: 1rem;
		margin: 0px;
		padding: 5px;
	}
}

.anc {
	&.loading {
		pointer-events: none;
		cursor: default;
		filter: brightness(0.9);
	}
}

.filter-by-sns {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	margin-bottom: 30px;
	span {
		display: flex;
		input {
			margin: 0;
			border-radius: 3px 0 0 3px;
		}
		button {
			pointer-events: none;
			cursor: default;
			padding: 7px 10px;
			border-radius: 0 3px 3px 0;
		}
	}
}
.rdt_Table {
	border-bottom: 1px solid $black;
	.rdt_TableHead {
		.rdt_TableHeadRow {
			background: $black;
			color: $white;
			font-size: 16px;
			font-weight: 700;
			.rdt_TableCol {
				padding: 10px 18px;
				text-align: center;
				border-left: 1px solid $light-grey;
				&:nth-child(1),
				&:nth-child(2) {
					border: unset;
				}
				.rdt_TableCol_Sortable {
					color: $white;
				}
			}
		}
	}
	.rdt_TableBody {
		.rdt_TableRow {
			border-bottom: none;
			.rdt_TableCell {
				font-size: 16px;
				&:nth-child(1) {
					text-decoration: underline;
				}
				&:nth-child(n + 2) {
					font-weight: 700;
				}
			}
			&:nth-child(odd) {
				background: $light-grey;
			}
		}
	}
}

//lpr store
#tableMacStoreReport {
	width: 100%;
	thead {
		tr {
			th {
				padding: 10px 18px;
				border-bottom: 1px solid #111;
				&[data-visible="false"] {
					display: none;
				}
				text-align: left;
			}
		}
		.tr-first th {
			border: none;
			padding: 5px;
			margin: 0;
			background: #333;
			color: #fff;
		}
	}
	tbody {
		tr {
			border-top: 1px solid #ddd;
			td {
				&:first-child {
					display: none;
				}
				text-align: left;
				padding: 8px 10px;
			}
			&:nth-child(even) {
				background-color: #fff;
			}
			&:nth-child(odd) {
				background-color: #f6f6f6;
			}
		}
	}
}

@media print {
	// .dt-print-view {
	// 	a[href]:after {
	// 		content: none !important;
	// 	}
	// 	.report-table svg {
	// 		height: 12px;
	// 	}
	// 	.add-to-print img {
	// 		height: 25px;
	// 	}
	// 	.report-table tr th {
	// 		border-right: 1px solid black;
	// 		border-top: 1px solid black;
	// 		text-align: center;
	// 	}
	// 	.report-table tr th:first-child {
	// 		border-left: 1px solid black;
	// 	}
	// }
	.dt-print-view {
		a[href]:after {
			content: none !important;
		}
		.col-xs-12.col-sm-4 {
			width: 33%;
		}
		.col-xs-12.col-sm-6 {
			width: 50%;
			margin-bottom: 30px;
		}
		.page-header {
			text-align: center;
			border-bottom: none;
		}
		.no-print {
			display: none;
		}
		.lpr-options {
			display: none;
		}
		//table
		.rdt_Table {
			border-bottom: 1px solid $black;
			.rdt_TableHead {
				.rdt_TableHeadRow {
					font-size: 16px;
					font-weight: 700;
					.rdt_TableCol {
						padding: 10px 18px;
						text-align: center;
						border: 1px solid $black;
						&:nth-child(1) {
							border-right: unset;
						}
						&:nth-child(2) {
							border-left: unset;
						}
						&:nth-child(n + 2) {
							justify-content: center;
							font-weight: 700;
						}
						.rdt_TableCol_Sortable {
							color: $white;
						}
					}
				}
			}
			.rdt_TableBody {
				.rdt_TableRow {
					border-bottom: 1px solid $dark-grey;
					.rdt_TableCell {
						font-size: 16px;
						&:nth-child(1) {
							text-decoration: underline;
						}
						&:nth-child(n + 2) {
							justify-content: center;
							font-weight: 700;
						}
					}
					&:nth-child(odd) {
						background: $light-grey;
					}
				}
			}
		}
	}
}

/*
  Learning Path Report Css End
*/

.ms-options-wrap,
.ms-options-wrap * {
	box-sizing: border-box;
}

.ms-options-wrap > button:focus,
.ms-options-wrap > button {
	position: relative;
	width: 100%;
	text-align: left;
	border: 1px solid #ccc;
	background-color: #fff;
	padding: 10px 20px 10px 5px;
	margin-top: 1rem;
	color: #333;
	outline-offset: -2px;
	white-space: nowrap;
}

.ms-options-wrap > button > span {
	display: inline-block;
	padding-left: 15px;
}

.ms-options-wrap > button[disabled] {
	background-color: #e5e9ed;
	color: #808080;
	opacity: 0.6;
}

.ms-options-wrap > button:after {
	content: " ";
	height: 0;
	position: absolute;
	top: 50%;
	right: 5px;
	width: 0;
	border: 6px solid rgba(0, 0, 0, 0);
	border-top-color: #333;
	margin-top: -3px;
}

.ms-options-wrap.ms-has-selections > button {
	color: #333;
}

.ms-options-wrap > .ms-options {
	position: absolute;
	right: 15px;
	width: 100%;
	margin-top: 1px;
	margin-bottom: 20px;
	background: white;
	z-index: 2000;
	border: 1px solid #aaa;
	overflow: auto;
	visibility: hidden;
}

.ms-options-wrap.ms-active > .ms-options {
	visibility: visible;
}

.ms-options-wrap > .ms-options > .ms-search input {
	width: 100%;
	padding: 4px 5px;
	border: none;
	border-bottom: 1px groove;
	outline: none;
}

.ms-options-wrap > .ms-options .ms-selectall {
	display: inline-block;
	font-size: 1rem;
	text-transform: lowercase;
	text-decoration: none;
}
.ms-options-wrap > .ms-options .ms-selectall:hover {
	text-decoration: underline;
}

.ms-options-wrap > .ms-options > .ms-selectall.global {
	margin: 4px 5px;
}

.ms-options-wrap > .ms-options > ul,
.ms-options-wrap > .ms-options > ul > li.optgroup ul {
	list-style-type: none;
	padding: 0;
	margin: 0;
}

.ms-options-wrap > .ms-options > ul li.ms-hidden {
	display: none;
}

.ms-options-wrap > .ms-options > ul > li.optgroup {
	padding: 5px;
}
.ms-options-wrap > .ms-options > ul > li.optgroup + li.optgroup {
	border-top: 1px solid #aaa;
}

.ms-options-wrap > .ms-options > ul > li.optgroup .label {
	display: block;
	padding: 5px 0 0 0;
	font-weight: bold;
}

.ms-options-wrap > .ms-options > ul label {
	position: relative;
	display: inline-block;
	width: 100%;
	//padding: 10px 4px 4px 20px;
	margin: 1px 0;
	border: 1px dotted transparent;
}
.ms-options-wrap > .ms-options.checkbox-autofit > ul label,
.ms-options-wrap > .ms-options.hide-checkbox > ul label {
	padding: 8px;
}

.ms-options-wrap > .ms-options > ul label.focused,
.ms-options-wrap > .ms-options > ul label:hover {
	background-color: #efefef;
	border-color: #999;
}

.ms-options-wrap > .ms-options > ul li.selected label {
	background-color: #efefef;
	border-color: transparent;
}

.ms-options-wrap > .ms-options > ul input[type="checkbox"] {
	margin: 5px 5px 0 0;
	position: absolute;
	left: 4px;
	top: 7px;
}

.ms-options-wrap > .ms-options.hide-checkbox > ul input[type="checkbox"] {
	position: absolute !important;
	height: 1px;
	width: 1px;
	overflow: hidden;
	clip: rect(1px 1px 1px 1px);
	clip: rect(1px, 1px, 1px, 1px);
}

.button {
	color: #7daff4;
	cursor: pointer;
	background-color: white;
}

.button.selected {
	color: black;
	cursor: default;
}

.checklist-view {
	font-size: 14px;
	font-weight: 700;
	line-height: 24px;
	letter-spacing: 0em;
	text-align: left;
	font-weight: 400;
	line-height: 24px;
	letter-spacing: 0em;
	text-align: left;
}

.listitemtask-view-container {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding: 0 15px;
	.options {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		gap: 0.25rem;
		.button {
			padding: 0;
			height: 1.5rem;
		}
	}
}

@media screen and (min-width: 510px) {
	.listitemtask-view-container {
		flex-direction: row;
		gap: 0.5rem;
	}
}

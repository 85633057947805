.checklist-report-more-info-container{
    display: flex;
    align-items: stretch;
    height: 50px;
}
.left-panel{
    padding: 10px;
    cursor: pointer;
    color: #00489C; 
}

.right-panel {
    flex: 1;
    padding: 10px;
    cursor: pointer;
    color: #00489C; 
}

.divider{
    width: 1.4px;
    height: 50%;
    background-color: rgb(160, 160, 160);
    align-self: center;
 }
@import "_variables.scss";
.task-tabs,
.nav-pills {
	padding-left: 0;
	margin-bottom: 20px;
	list-style: none;
	li {
		border-radius: 0;
		background: $white;
		padding: 5px 15px;
		border: 1px solid $mid-grey;
		text-align: center;
		a,
		.anc {
			color: $black;
			font-weight: $font-weight-bold;
			&:hover,
			&:focus {
				background: $white;
			}
		}
	}
	li.active {
		background: $yellow;
		color: $black;
		border: 1px solid $yellow;
		a,
		a:hover,
		a:focus,
		a:active {
			background: $yellow;
			color: $black;
		}
	}
}

/* what's new section */
ul.whats-new-filters {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
	list-style: none;
	padding-left: 0;
	a,
	.anc {
		flex-grow: 1;
		padding: 5px 15px;
		border: 1px solid $mid-grey;
		//margin: 5px;
		color: $black;
		font-weight: bold;
	}
	li {
		text-align: center;
	}
	.selected {
		background: $yellow;
		color: $black;
		border: 0;
	}
}

.update-container {
	border-bottom: 2px solid $light-grey;
	padding-bottom: 15px;
	.update-product {
		font-size: 1.5rem;
	}
	.update-date {
		text-align: center;
		margin-top: 40px;
		margin-bottom: 20px;
		color: black;
		font-weight: $font-weight-bold;
		color: $red;
	}
	.update-content {
		padding: 15px 0;
		ul {
			padding-bottom: 15px;
		}
		ul > li {
			line-height: 1.5rem;
		}
	}
}

.section-bottommargin {
	margin-bottom: 35px;
	line-height: 1.5rem;
}

.super-badge {
	max-width: 160px;
	margin: 0 auto;
	text-align: center;
}

.super-badge-count {
	position: absolute;
	left: 0;
	right: 0;
	top: 130px;
	font-size: 2rem;
	font-weight: bold;
	color: $black;
}

.super-badge-label {
	position: absolute;
	left: 0;
	right: 0;
	top: 170px;
	margin: 0 auto;
	font-size: 0.8rem;
	color: $black;
	font-weight: $font-weight-base;
}

ul.badges {
	list-style: none;
	padding-left: 0;
	width: 100%;
	margin: 0 auto;
	li {
		display: inline-block;
		margin-right: 5px;
		padding: 5px 0;
		//max-width: 120px;
		text-align: center;
		vertical-align: top;

		img {
			width: 60px;
			height: auto;
		}
		p {
			padding: 0 8px;
		}
	}
	&.my-badges {
		li {
			width: 160px;
			padding-bottom: 15px;
			p {
				margin-top: 15px;
				font-size: 0.9rem;
			}
		}
	}
}

.staff-roles {
	ul {
		padding-left: 0;
		list-style: none;
		li {
			padding: 15px;
		}
	}
	&.mobile {
		padding-bottom: 20px;
		ul {
			overflow: auto;
			li {
				float: none;
				display: inline-block;
			}
		}
	}
}

.staff-title {
	h5 {
		font-weight: 600;
		color: $red;
	}
}

.row-equalize {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
}

/*my settings page new*/

.arrow {
	border: solid black;
	border-width: 0 2px 2px 0;
	display: inline-block;
	padding: 3px;
	height: max-content;
}

.right {
	transform: rotate(-45deg);
	-webkit-transform: rotate(-45deg);
}

.left {
	transform: rotate(135deg);
	-webkit-transform: rotate(135deg);
}

.up {
	transform: rotate(-135deg);
	-webkit-transform: rotate(-135deg);
}

.down {
	transform: rotate(45deg);
	-webkit-transform: rotate(45deg);
}

.systems {
	position: relative;
	background: white;
	border: 1px solid #ccc;
	cursor: pointer;
	.systemsTop {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 7px 20px;
	}
	.options {
		background: white;
		border: 1px solid gray;
		z-index: 9999999;
		position: absolute;
		top: 100%;
		left: 0;
		display: flex;
		flex-flow: column;
		.option {
			width: 100%;
			padding: 3px 10px;
			cursor: pointer;
			&:hover {
				background: lightgray;
			}
		}
	}
}

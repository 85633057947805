.checklist-report-detail {
	&.main {
		padding: 0;
	}
	.checklist-report-detail-header {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		.checklist-report-detail-header-left {
			padding: 10px 15px;
		}
		.checklist-report-detail-header-right {
			padding: 10px 15px;
			border-top: 1px solid #b1b1b1;
			border-bottom: 1px solid #b1b1b1;
		}
	}
	.mobile-list-container {
		.mobile-list-item {
			display: flex;
			flex-direction: column;
			gap: 0.75rem;
			border-bottom: 1px solid #b1b1b1;
			padding: 10px 0;
			&:nth-child(even) {
				background-color: #f2f2f2;
			}
			.mobile-list-item-row {
				display: flex;
				.mobile-list-item-cell {
					width: 50%;
					max-width: 50%;
					text-align: left;
					font-size: 1rem;
					color: #000;
					&.label {
						font-weight: bold;
						white-space: unset;
					}
					&.value {
						&.anc {
							cursor: pointer;
							color: var(--links-color);
							&:hover {
								text-decoration: underline;
							}
						}
					}
				}
			}
		}
	}
}

.checklist-report-detail-header-title {
	color: var(--headings-color); //styleName: Heading 1;
	font-size: 20px;
	font-weight: 700;
	text-align: left;
}

.checklist-report-detail-header-right {
	color: var(--headings-color); //styleName: Heading 1;
	font-size: 20px;
	font-weight: 700;
	text-align: left;
}

.checklist-report-detail-header-information {
	//styleName: Paragraph Bold;
	font-family: Open Sans;
	font-size: 14px;
	font-weight: 700;
	line-height: 22px;
	letter-spacing: 0em;
	text-align: left;
}

.checklist-report-table-element {
	vertical-align: baseline;
}

@media screen and (min-width: 510px) {
	.checklist-report-detail {
		&.main {
			padding: 30px 15px 60px;
		}
		.checklist-report-detail-header {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			padding: 10px 15px;
			.checklist-report-detail-header-left {
				padding: 0;
			}
			.checklist-report-detail-header-right {
				padding: 0;
				border: none;
			}
		}
	}
}

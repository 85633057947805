.top-modal {
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 999999;
	.tm-card {
		background: white;
		border-radius: 0.25rem;
		color: black;
		max-height: 90%;
		margin-top: auto;
		margin-bottom: auto;
		max-width: 100%;
		overflow-y: auto;
		display: flex;
		flex-direction: column;
		.tm-card-header {
			display: flex;
			justify-content: space-between;
			padding: 0.5rem 1rem;
			.title {
				font-weight: bold;
				font-size: large;
			}
			.close {
				color: black;
				width: 100%;
				justify-self: end;
				opacity: 0.6;
				margin-top: 1rem;
				margin-right: 1rem;
				cursor: pointer;
			}
		}
		.tm-content {
			display: flex;
			flex-direction: column;
			overflow-y: auto;
			overflow-x: hidden;
			padding-left: 1rem;
			padding-right: 1rem;
		}
	}
	&:before {
		content: "";
		width: 100%;
		height: 100%;
		background-color: rgba(0, 0, 0, 0.5);
		position: absolute;
		z-index: -1;
	}
}

.footer-debugger {
	position: fixed;
	bottom: 0;
	left: 0;
	display: block;
	padding: 1rem;
	background: $black;
	color: $white;
	z-index: 1000;
	border-top-right-radius: 5px;

	p {
		margin: 0;
	}
}

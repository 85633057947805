.checklistTaskModal {
	display: flex;
	flex-direction: column;
	gap: 0.5rem;
	border-top: 1px solid #ddd;
	// padding-top: 1.5rem;
	.details {
		padding: 0.75rem;
		display: flex;
		flex-direction: column;
		gap: 0.5rem;
	}
	.notes {
		padding: 0.75rem;
	}
	.tabs {
		display: flex;
		gap: 1rem;
		border-bottom: #c5c5c5 solid 1px;
		height: 48px;
		align-items: flex-end;
		.tab {
			cursor: pointer;
			color: #333;
			font-weight: 400;
			border-bottom: 2px solid transparent;
			padding: 0.5rem;
			padding-left: 1.5rem;
			padding-right: 1.5rem;
			&:hover {
				background: #e8e8e8;
				border-top-left-radius: 4px;
				border-top-right-radius: 4px;
			}
			&.active {
				// color: rgb(191, 12, 12);
				font-weight: 700;
				border-bottom: 2px solid #ffce00;
			}
			.dot {
				border-radius: 100%;
				width: 0.5rem;
				height: 0.5rem;
				background: rgb(191, 12, 12);
				display: inline-block;
				margin-left: 0.5rem;
			}
		}
	}
	.item {
		.label {
			all: unset;
			color: #333;
			font-weight: bold;
		}
		.value {
			padding-left: 0.5rem;
			&.blue-title {
				font-weight: bold;
				color: #00489c;
			}
			.unavailable {
				opacity: 0.5;
			}
		}
	}
	.status-area {
		display: flex;
		justify-content: space-between;
		gap: 1rem;
		flex-wrap: wrap;
		border-radius: 0.375rem;
		background-color: #f6f6f6;
		padding: 0.75rem;
		.label {
			all: unset;
			color: #333;
			font-weight: bold;
		}
	}
	.button-area {
		display: flex;
		justify-content: flex-end;
		gap: 1rem;
		padding-top: 0.75rem;
	}
}

@media screen and (min-width: 510px) {
	.checklistTaskModal {
		.status-area {
			min-width: 40rem;
		}
		.form-items {
			min-width: 40rem;
		}
	}
}
